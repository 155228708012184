#section-creation-main-container {
	width: 100%;
    max-width: 1200px;
    margin: 0px auto 0px auto;
    padding: 20px 20px 200px 20px;
    text-align: center;
}

.section-creation-breadcrumb-container {
	max-width: 1100px;
}

.section-creation-no-data-container {
	width: 100%;
    display: inline-block;
    padding: 30px 30px;
    margin: 20px 0px 20px 0px;
    background-color: #fff;
    border: 2px solid #efefef;
    color: #2c3e50;
    border-radius: 8px;
    font-size: 14px;
}

.section-creation-department-complete-check {
	position: absolute;
	display: flex;
	top:22px;
	right:15px;
	font-size:20px;
}

.section-creation-screen-message {
	width: 100%;
    display: grid;
    align-items: center;
    justify-items: center;
    padding: 60px 50px 70px 50px;
    border-radius: 10px;
	background-color:#fff;
    color: #2c3e50;
}

.section-creation-message-container {
	padding:30px;
	text-align: center;
}

.section-creation-btn-row {
	display: grid;
	grid-template-columns: 1fr 1fr;
	column-gap: 10px;
	margin:10px 0px 0px 0px;
}

.section-creation-supervisor-inner-container {
	float: left;
    display: grid;
    grid-template-columns: auto 1fr;
    column-gap: 10px;
    margin: 0px 10px 0px 0px;
    align-items: center;
    border: 1px solid #ddd;
    border-radius: 20px;
    padding: 6px 10px 6px 10px;
}

.section-creation-department-supervisor-heading {
	margin:0px 0px 5px 0px;
}

.section-creation-supervisor-img {
	width:25px;
	height:25px;
	border-radius: 20px;
}

.section-creation-department-supervisor {
	font-size: 14px;
}

.section-creation-add-supervisor-link {
	margin:0px 0px 0px 10px;
}

/**************************/
/***** SECTION REVIEW *****/
/**************************/
.section-review-section-creation-options-container {
	display: grid;
	column-gap: 30px;
	grid-template-columns: repeat(auto-fit, minmax(100px, 350px));
	max-width: 1400px;
}

.section-review-missing-requests-container {
	position: relative;
    height: 100%;
    background-color: #fff;
    border-radius: 10px;
    border: 3px solid #efefef;
    padding: 65px 40px 40px 40px;
    max-width: 400px;
}

.section-review-creation-option-header {
	min-height: 30px;
}

.section-review-creation-option-btn-container {
	display: grid;
	align-items: center;
	min-height: 100px;
}

.section-review-creation-option-btn {
	height: fit-content;
}

.section-review-creation-option-select-container {
	display: grid;
    position: absolute;
    top: 20px;
    right: 20px;
    width: 30px;
    height: 30px;
    background-color: #fff;
    border: 3px solid #efefef;
    border-radius: 20px;
    cursor: pointer;
    align-items: center;
    justify-content: center;
}

.section-review-creation-option-select-container-inner {
	height: 18px;
    width: 18px;
    border-radius: 20px;
}

.section-review-creation-option-select-container-inner-selected, .section-review-creation-option-select-container-inner:hover {
	background-color: #3498db;
}

.section-review-currently-unavailable-container {
	position: absolute;
    top: 5px;
    right: 20px;
    /*width: 180px;*/
    text-align: right;
}

#section-review-create-sections-btn {
	max-width: 200px;
	background-color: #fafafa;
	border:2px solid #efefef;
	color:#ddd;
	margin:40px 0 0px auto;
	border-radius: 30px;
	cursor:not-allowed;
}

#section-review-create-sections-btn-active 
{
	max-width: 200px;
	background-color: #2980b9;
	border:2px solid #2980b9;
	margin:50px 0 0px auto;
	border-radius: 30px;
	cursor: pointer;
}

#section-review-create-sections-btn-active:hover {
	background-color: #3498db;
	border:2px solid #3498db;
}

#custom-section-creation-loader-container {
	display: grid;
    width: fit-content;
    grid-template-columns: 30px 30px 30px 30px;
    grid-column-gap: 10px;
    column-gap: 10px;
    margin: 0px auto 40px auto;
}

.custom-section-creation-loader-column {
	position: relative;
	display: grid;
	grid-template-rows: 30px;
	row-gap: 5px;
}

.custom-section-creation-loader-row {
	display: grid;
	grid-template-rows: 10px 20px;
}

.custom-section-creation-loader-row-top {
	border-radius: 3px 3px 0px 0px;
}

.custom-section-creation-loader-row-bottom {
	border:1px solid #efefef;
	border-top: none;
	border-radius: 0px 0px 3px 3px;
}

.custom-section-creation-loader-row:nth-child(1) {
	animation: display-first-section 3s infinite;
}

.custom-section-creation-loader-row:nth-child(2) {
	animation: display-second-section 3s infinite;
}

.custom-section-creation-loader-row:nth-child(3) {
	animation: display-third-section 3s infinite;
}

@keyframes display-first-section {
	0%   {width: 0%;opacity:0;}
	33%	 {width:100%;opacity:1;}
}

@keyframes display-second-section {
	0%   {width: 0%;opacity:0;}
	33%	 {width: 0%;opacity:0;}
	66%	 {width: 100%;opacity:1;}
}

@keyframes display-third-section {
	0%   {width: 0%;opacity:0;}
	33%	 {width: 0%;opacity:0;}
	66%	 {width: 0%;opacity:0;}
	100% {width: 100%;opacity:1;}
}

#section-review-dashboard {
	width: 100%;
    max-width: 1200px;
    min-width: 860px;
    display: grid;
}

#section-review-sidebar {
	height: fit-content;
	min-height: 600px;
	background-color: #2c3e50;
	text-align: left;
	padding: 0px 0px 20px 0px;
	border-radius: 8px;
	box-shadow: 0 0 5px rgba(110, 103, 86, 0.15);
}

.section-review-sidebar-btn {
	position: relative;
	width:100%;
	padding:20px 20px 20px 40px;
	font-size: 14px;
	color:#8ba7c4;
	cursor: pointer;
}

.section-review-sidebar-btn:not(.section-review-sidebar-btn-selected):hover {
	background-color:#34495e;
	color:#efefef;
}

.section-review-sidebar-icon {
	margin:0px 14px 0px 0px;
}

.section-review-sidebar-btn-selected {
	background-color:#2980b9;
	color:#fff;
}

.section-review-sidebar-header {
	width:100%;
	padding: 25px 0px 20px 25px;
	font-size: 14px;
	font-weight: bold;
	color:#fff;
}

#section-review-content {
	display: grid;
	grid-template-columns: auto 1fr;
	position: relative;
	background-color:#fff;
	border-radius: 10px;
	box-shadow: 0 0 5px rgba(110, 103, 86, 0.15);
	text-align: left;
}

.section-review-content-inner-content {
	padding:20px 30px;
}

#section-review-submit-btn-container {
	display: grid;
	grid-template-columns: 1fr;
	position: absolute;
    top: 25px;
    right: 25px;
	justify-items: end;
}

#section-review-submit-btn-container .gray-btn {
	max-width: 127px;
	margin:0px;
}

.section-review-content-department-header {
	margin:0px 0px 5px 0px;
	color: #a9a9a9;
}

.section-review-content-header-container {
	display: inline-grid;
	grid-template-columns: auto auto;
	column-gap: 20px;
	margin:0px 150px 5px 0px;
	align-items: center;
}

.section-review-content-header {
	margin:0px 0px 0px 0px;
	color: #2c3e50;
}

.section-review-status {
	display: grid;
    grid-template-columns: auto 20px;
    column-gap: 7px;
    align-items: center;
    padding: 5px 0px 0px 0px;
}

.section-review-supervisor-inner-container {
	float:left;
	display: grid;
	grid-template-columns: 35px 1fr;
	margin:6px 10px 0px 0px;
	align-items: center;
	border:1px solid #ddd;
	border-radius: 20px;
	padding:6px 10px 6px 10px;
}

.section-review-supervisor-user-icon {
	font-size: 25px;
}

.section-review-supervisor-img {
	width:25px;
	height:25px;
	border-radius: 20px;
}

.section-review-department-supervisor {
	font-size: 14px;
}

.section-review-no-data-container {
	padding:20px;
	margin:30px auto 0px auto;
	border:3px dashed #efefef;
	border-radius:5px;
	text-align: center;
	font-size: 24px;
	color:#2c3e50;
}

.section-review-btn-container {
	display: grid;
	grid-template-columns: 1fr auto;
	align-items: center;
    margin: 25px 0px 0px 0px
}

#section-review-change-view {
	display: grid;
    grid-template-columns: 300px 1fr;
    align-items: center;
    column-gap: 20px;
}

#section-review-change-view > .dropdown-container {
	padding:10px 5px 10px 10px;
}

#section-review-change-layout-container {
	display: grid;
	grid-template-columns: auto auto 1fr;
	align-items: center;
	column-gap: 15px;
	align-items: center;
}

.section-review-change-layout-btn {
	position: relative;
	width: fit-content;
	display: grid;
	grid-template-columns: auto 1fr;
	padding:10px 15px;
	background-color:#efefef;
	border-radius: 5px;
	align-items: center;
	column-gap: 10px;
	font-size: 14px;
	background-color: #ecf0f1;
	cursor: pointer;
}

.section-review-change-layout-btn.section-review-more-settings {
	grid-template-columns: 1fr !important;
	column-gap: 0px;
	padding:13px 18px;
}

.section-review-change-layout-btn:hover {
	background-color: #e8eaeb;
}

.section-review-data-container {
	margin: 20px 0px 0px 0px;
    background-color: #fcfcfc;
    border-radius: 8px;
}

.section-review-no-data-icon {
	font-size: 34px;
	margin:0px 0px 10px 0px;
}

.section-review-no-data-container > p {
	font-size: 14px;
}

.section-review-row-container {
	padding: 30px;
    border: 2px solid #efefef;
    margin-bottom: 60px;
}

.section-review-main-name-container {
	display: grid;
    grid-template-columns: 1fr auto;
    align-items: top;
    margin: 0px 0px 0px 0px;
    border-radius: 10px 10px 0px 0px;
}

.section-review-main-name-display-name-container {
	text-align: left;
}

.section-review-main-name {
	margin: 0px;
    color: #2c3e50;
	font-size:34px;
}

.section-review-stats-container {
	display: grid;
	justify-items: right;
}

.section-review-request-count {
	display: grid;
    grid-template-columns: 1fr 15px auto;
    text-align: right;
    grid-column-gap: 10px;
    grid-column-gap: 10px;
    column-gap: 10px;
    margin: 5px 0px 0px 0px;
    font-size: 14px;
    /* justify-content: right; */
    justify-items: right;
}

.section-review-estimated-class-size {
	border: 2px solid #efefef;
    padding: 10px;
    background-color: #fff;
    width: -moz-fit-content;
    width: fit-content;
    margin: 15px 0px 10px 0px;
    border-radius: 5px;
    text-align: center;
    border: 2px solid #ddd;
}

.section-review-subname {
	margin: 30px 0px 10px 0px;
    color: #2c3e50;
	/*max-width: 220px;*/
    font-size: 20px;
    /*border-bottom: 1px dashed #a9a9a9;*/
}

.section-review-subname > h4 {
	margin:0px;
}

.section-review-subname-stats {
	display: grid;
	grid-template-columns: 1fr auto;
	font-size: 14px;
    margin: 3px 0px 3px 0px;
	align-items: center;
}

.section-review-grid-sections-container {
	display: grid;
    grid-template-columns: repeat(3, 1fr);
    column-gap: 30px;
    row-gap: 10px;
    margin: 10px 0px 0px 0px;
}

.section-review-subname-column {
	display: grid;
    align-items: start;
    /* grid-template-rows: 36px; */
    grid-auto-rows: max-content;
}

.section-review-section-container {
	position: relative;
    height: fit-content;
    margin:0px 0px 10px 0px;
}

.section-review-section-container.row-view {
	padding:5px 5px 10px 5px;
	margin:0px;
	border: 1px solid #f1f1f1;
}

.section-review-section-container.row-view:nth-child(odd)
{
	background-color: #f1f1f1;
}

.section-review-section-container.row-view:nth-child(odd) .fas-checkbox-unchecked {
	color:rgb(228, 228, 228);
}

.section-review-section-container.row-view:nth-child(odd) .fas-checkbox-unchecked:hover {
	color:#ccc;
}

.section-review-section-header {
	display: grid;
    grid-template-columns: auto 1fr auto auto auto;
    column-gap: 12px;
    align-items: center;
    padding: 5px 8px 5px 8px;
    border-radius: 8px 8px 0px 0px;
    background-color: #2c3e50;
    color: #fff;
    font-size: 14px;
}

.section-review-section-content {
	padding: 5px 10px 5px 10px;
    border-radius: 0px 0px 8px 8px;
    border: 1px solid #dfdfdf;
    background-color: #fff;
    border-top: none;
}

.section-review-row-content {
	display: grid;
    grid-template-columns: 120px 1fr 1fr 100px 120px;
    column-gap: 50px;
	row-gap: 15px;
    align-items: center;
}

.section-review-row-view-btn-container {
	height: 100%;
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(18px, 1fr));
    grid-column-gap: 10px;
    column-gap: 10px;
    align-items: center;
}

.section-review-row-view-icr-checkbox-container {
	height: 100%;
	display: grid;
	grid-template-columns: auto 1fr;
	column-gap: 8px;
	font-size: 12px;
	align-items: center;
}

.subsection-review-row-view-tag {
	padding:3px 5px;
	border-radius: 3px;
	text-align: center;
}

.section-review-data {
    margin: 0px 2px 4px 2px;
    font-size: 14px;
	max-width: 174px;
}

.section-review-section-name {
	position: relative;
	display: inline-block;
	margin:0px 0px 0px 0px;
	font-size: 16px;
	color:#2c3e50;
}

.section-review-section-subname {
	position: relative;
	display: grid;
	grid-template-columns: 1fr 50px;
	margin:0px 0px 0px 0px;
	font-size: 14px;
	color:#2c3e50;
}

.section-review-edit-label {
	font-size: 12px;
    color: #a9a9a9;
    margin: 0px 0px 0px 0px;
}

.section-review-add-new-section {
	margin:0px 0px;
}

.section-review-search-add-search-results {
    margin: 0px 0px 10px 0px;
    padding: 0px 10px 0px 0px;
    max-height: 300px;
    overflow-y: auto;
    font-size: 14px;
}

.section-review-icr-checkbox-container {
	display: grid;
	grid-template-columns: auto 1fr;
	column-gap: 8px;
	margin:10px 0px 0px 0px;
	font-size: 12px;
	align-items: center;
}

.section-review-icr-checkbox {
	font-size: 14px;
}

.section-review-inclusion-container {
	position: relative;
	padding:5px 5px 5px 5px;
	border:1px solid #efefef;
	border-radius: 8px;
	margin:5px 0px 0px 0px;
}

.section-review-inclusion-toggle-container {
	display: grid;
    grid-template-columns: 1fr auto;
    text-align: right;
    align-items: center;
    column-gap: 10px;
    font-size: 12px;
}

.change-inclusion-teacher-link {
	position:absolute;
	bottom:3px;
	right:5px;
}

.section-review-cancel-subsection {
	position:absolute;
	right:9px;
	top:8px;
}

.section-review-inclusion-data {
    margin: 0px 0px 4px 2px;
    font-size: 14px;
    max-width: 176px;
	padding:3px 0px 3px 0px;
}

.section-review-inclusion-data:not(.section-review-inclusion-no-data):hover {
	border:2px solid #efefef;
	border-radius: 5px;
	cursor: pointer;
	padding-left: 3px;
}

/*****************************/
/***** SUBSECTION REVIEW *****/
/*****************************/

#subsection-review-dashboard {
	width: 100%;
    max-width: 1200px;
    min-width: 860px;
    display: grid;
}

#subsection-review-sidebar {
	height: fit-content;
	min-height: 600px;
	background-color: #2c3e50;
	text-align: left;
	padding: 0px 0px 20px 0px;
	border-radius: 8px;
	box-shadow: 0 0 5px rgba(110, 103, 86, 0.15);
}

.subsection-review-sidebar-btn {
	position: relative;
	width:100%;
	padding:20px 20px 20px 40px;
	font-size: 14px;
	color:#8ba7c4;
	cursor: pointer;
}

.subsection-review-sidebar-btn:not(.subsection-review-sidebar-btn-selected):hover {
	background-color:#34495e;
	color:#efefef;
}

.subsection-review-sidebar-icon {
	margin:0px 14px 0px 0px;
}

.subsection-review-sidebar-btn-selected {
	background-color:#2980b9;
	color:#fff;
}

.subsection-review-sidebar-header {
	width:100%;
	padding: 25px 0px 20px 25px;
	font-size: 14px;
	font-weight: bold;
	color:#fff;
}

#subsection-review-content {
	display: grid;
	grid-template-columns: auto 1fr;
	position: relative;
	background-color:#fff;
	border-radius: 10px;
	box-shadow: 0 0 5px rgba(110, 103, 86, 0.15);
	text-align: left;
}

.subsection-review-content-inner-content {
	padding:20px 30px;
}

#subsection-review-submit-btn-container {
	display: grid;
	grid-template-columns: 1fr;
	position: absolute;
    top: 25px;
    right: 25px;
	justify-items: end;
}

#subsection-review-submit-btn-container .gray-btn {
	max-width: 127px;
	margin:auto;
}

.subsection-review-content-department-header {
	margin:0px 0px 5px 0px;
	color: #a9a9a9;
}

.subsection-review-content-header-container {
	display: inline-grid;
	grid-template-columns: auto auto;
	column-gap: 20px;
	margin:0px 150px 5px 0px;
	align-items: center;
}

.subsection-review-content-header {
	margin:0px 0px 0px 0px;
	color: #2c3e50;
}

.subsection-review-status {
	display: grid;
    grid-template-columns: auto 20px;
    column-gap: 7px;
    align-items: center;
    padding: 5px 0px 0px 0px;
}

.subsection-review-supervisor-inner-container {
	float:left;
	display: grid;
	grid-template-columns: 35px 1fr;
	margin:6px 10px 0px 0px;
	align-items: center;
	border:1px solid #ddd;
	border-radius: 20px;
	padding:6px 10px 6px 10px;
}

.subsection-review-supervisor-user-icon {
	font-size: 25px;
}

.subsection-review-supervisor-img {
	width:25px;
	height:25px;
	border-radius: 20px;
}

.subsection-review-department-supervisor {
	font-size: 14px;
}

.subsection-review-no-data-container {
	padding:20px;
	margin:30px auto 0px auto;
	border:3px dashed #efefef;
	border-radius:5px;
	text-align: center;
	font-size: 24px;
	color:#2c3e50;
}

.subsection-review-toggle-screen-container {
	display: grid;
    margin: 20px 0px 0px 0px;
    grid-template-columns: 1fr 1fr;
}

.subsection-review-toggle-screen-btn {
	position: relative;
    padding: 20px;
    text-align: center;
    color: #a9a9a9;
    cursor: pointer;
    border-top: 3px solid #f3f3f3;
    background-color: #f3f3f3;
}

.subsection-review-toggle-screen-btn:not(.subsection-review-toggle-screen-btn-selected) {
	border-bottom:1px solid #efefef;
}

.subsection-review-toggle-screen-btn:hover {
	color:#2c3e50;
}

.subsection-review-toggle-screen-btn-selected {
	color: #2c3e50;
    background-color: #fff;
    border-top: 4px solid #2980b9;
    border-left: 1px solid #efefef;
    border-right: 1px solid #efefef;
}

.subsection-review-toggle-screen-btn-label {
	display: inline-block;
}

.subsection-review-toggle-btn-counter {
	position: absolute;
    display: flex;
    top: 20px;
    right: 20px;
    width: 28px;
    height: 28px;
    justify-content: center;
    align-items: center;
    border-radius: 14px;
    font-size: 12px;
    background-color: #fff;
    color: #a9a9a9;
}

.subsection-review-toggle-screen-btn-selected .subsection-review-toggle-btn-counter {
	background-color: #2980b9 !important;
    color: #fff !important;
}

.subsection-review-data-container {
	margin: 0px 0px 20px 0px;
    border-top:none;
}

.subsection-review-data-container-top-bar {
	background-color: #fff;
	padding: 10px 20px 10px 20px;
	border-bottom: 1px dashed #a9a9a9;
    border-right:1px solid #efefef;
    border-left:1px solid #efefef;
}

.subsection-review-data-container-top-bar-inner-container {
	display: grid;
	grid-template-columns: repeat(3, 1fr);
	gap: 30px;
}

.subsection-review-data-outer-container {
    padding: 0px 15px 20px 20px;
    min-height: 500px;
    max-height: 600px;
    overflow-y: auto;
    background-color: #f3f3f3;
}

.subsection-review-data-inner-container {
	display: grid;
	grid-template-columns: repeat(3, 1fr);
	gap: 30px;
}

.subsection-review-data-column {
	display: grid;
    align-items: start;
    grid-auto-rows: max-content;
}

.subsection-review-column-heading {
	margin:0px;
	text-align: center;
	color:#2c3e50;
}

.subsection-review-no-data-icon {
	font-size: 34px;
	margin:0px 0px 10px 0px;
}

.subsection-review-no-data-container > p {
	font-size: 14px;
}

.subsection-review-section-container {
	position: relative;
    height: fit-content;
    margin:14px 0px 0px 0px;
}

.subsection-review-section-header {
	position: relative;
	display: grid;
	grid-template-columns: 1fr auto auto;
	column-gap: 15px;
	align-items: center;
	background-color:#2c3e50;
	color:#fff;
	border-radius: 8px 8px 0px 0px;
	padding:5px 8px;
	font-size: 14px;
}

.subsection-review-section-header.turquoise {
	padding:3px 8px;
}

.subsection-review-section-content {
	position: relative;
	padding: 10px 10px 10px 10px;
    border-radius: 0px 0px 8px 8px;
    border:1px solid #dfdfdf;
    background-color: #fff;
    border-top: none;
}

.subsection-review-subsection-content {
	position: relative;
	padding: 5px 10px 5px 10px;
    border-radius: 0px 0px 8px 8px;
    border:1px solid #dfdfdf;
    background-color: #fff;
    border-top: none;
}

.subsection-review-subsection-status {
	position: absolute;
    top: 5px;
    right: 5px;
    font-size: 12px;
    padding: 1px 8px;
    border-radius: 10px;
}

.subsection-review-data {
    margin: 0px 0px 4px 2px;
    font-size: 14px;
    max-width: 176px;
	padding:3px 0px 3px 0px;
}

.subsection-review-section-name {
	position: relative;
	display: inline-block;
	margin:0px 0px 0px 0px;
	font-size: 16px;
	color:#2c3e50;
}

.subsection-review-section-subname {
	position: relative;
	display: grid;
	grid-template-columns: 1fr 50px;
	margin:0px 0px 0px 0px;
	font-size: 14px;
	color:#2c3e50;
}

.subsection-review-edit-label {
	font-size: 12px;
	color:#a9a9a9;
	margin:0px 0px 0px 0px;
}

.subsection-review-section-add-subsection-container {
	display: grid;
    grid-template-columns: 1fr auto;
    column-gap: 15px;
    align-items: center;
    margin: 5px 0px 0px 0px;
}

.subsection-review-previously-rejected {
	font-size: 14px;
    margin: 20px 0px -4px 0px;
    border-bottom: 1px dashed;
    text-align: center;
}

.subsection-review-add-new-section {
	margin:30px 0px 0px 0px;
}

.subsection-review-search-add-search-results {
    margin: 0px 0px 0px 0px;
    padding: 0px 10px 0px 0px;
    max-height: 300px;
    overflow-y: auto;
    font-size: 14px;
}

.subsection-review-btn-container {
	display: grid;
	grid-template-columns: repeat(auto-fit, minmax(50px,1fr));
	column-gap: 10px;
	margin:20px 0px 0px 0px;
}

.subsection-review-accept-btn {
    display: grid;
    place-content: center;
	margin: 0px;
    padding: 4px 10px 4px 10px;
    background-color: #2ecc71;
    color: #fff;
    border-radius: 5px;
    font-size: 14px;
    cursor: pointer;
}

.subsection-review-reject-btn {
    display: grid;
    place-content: center;
	margin: 0px;
    padding: 3px 10px 3px 10px;
    border-radius: 5px;
    background-color: #efefef;
    color:#a9a9a9;
    font-size: 14px;
    cursor: pointer;
}

.subsection-review-override-btn {
	display: grid;
    place-content: center;
    margin: 0px;
    padding: 6px 10px 6px 10px;
    border-radius: 5px;
    border: 2px solid #efefef;
    color: #bbb;
    background-color: #fafafa;
    font-size: 14px;
    cursor: pointer;
}

.subsection-review-remove-btn {
	display: grid;
	width:20px;
    place-content: center;
    margin: 0px;
    padding: 6px 10px 6px 10px;
    border-radius: 5px;
    border: 3px solid #efefef;
    color: #bbb;
    background-color: #fafafa;
    font-size: 14px;
    cursor: pointer;
}

.subsection-review-accept-btn:hover {
	background-color: #39d57a;
}

.subsection-review-reject-btn:hover {
    background-color: #efefef;
    color:#bdbbbb;
}

.subsection-review-override-btn:hover {
	color: #a9a9a9;
}


.subsection-review-add-new-subsection-container {
	display: grid;
	grid-template-columns: 1fr auto 1fr auto 1fr;
	column-gap: 20px;
	align-items: center;
}

.subsection-review-search-sections-container {
	position: relative;
	padding:20px;
	border:2px solid #efefef;
	border-radius: 8px;
    margin: 8px 0px 0px 0px;
}

.subsection-review-close-search {
	position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 36px;
    height: 36px;
    font-size: 20px;
    top: 10px;
    right: 10px;
    color: #ddd;
    border-radius: 20px;
    cursor: pointer;
}

.subsection-review-close-search:hover {
	background-color: #efefef;
	color:#a9a9a9;
}

.subsection-review-close-search-icon {
	margin:2px 0px 0px 0px;
}

.subsection-review-search-header {
	display: grid;
    grid-template-columns: auto 1fr;
    align-items: center;
    column-gap: 15px;
    font-size: 1.2em;
    font-weight: bold;
}

.subsection-review-search-bar {
	height: 40px;
	line-height: 40px;
	font-size: 14px;
	width: 60%;
}

.subsection-review-search-results {
	display: grid;
	grid-template-columns: repeat(3, 1fr);
	gap: 30px;
	margin:20px 0px 0px 0px;
	color:#000;
}

.subsection-review-select-section {
	margin:20px 0px 0px 0px;
}

/*************************/
/***** CREATE LABELS *****/
/*************************/

#create-labels-dashboard {
	width: 100%;
    max-width: 1200px;
    min-width: 860px;
    margin: auto;
    text-align: left;
}

#create-labels-create-new-label-container {
	display: grid;
	grid-template-columns: 1fr 1fr;
	column-gap: 60px;
}

.create-labels-label-container {
	padding:10px 20px 20px 20px;
	margin: 0px 0px 30px;
	background-color: #fff;
	border-radius: 8px;
	box-shadow: 0 0 5px rgba(110, 103, 86, 0.15);
}

.create-labels-label-container-header-container {
	display: grid;
	grid-template-columns: auto 30px 1fr;
	column-gap: 8px;
	align-items: center;
	margin:0px 0px 5px 0px;
}

.create-labels-label-container-header-container > h2 {
	margin:0px;
}

.create-labels-label-container > p {
	margin:0px 0px 5px 0px;
}

.create-labels-label {
	display: grid;
	grid-template-columns: auto 1fr auto;
	column-gap: 10px;
	align-items: center;
	margin:7px 0px 0px 0px;
	padding:8px 10px 8px 10px;
	border:1px solid #efefef;
	border-radius: 30px;
}

.create-labels-editing-label {
	display: grid;
	grid-template-columns: auto 1fr auto auto;
	column-gap: 10px;
	align-items: center;
	margin:10px 0px 0px 0px;
	padding:8px 10px 8px 10px;
	border:1px solid #efefef;
	border-radius: 30px;
}

.create-labels-icon {
	width:8px;
	height:8px;
	border-radius: 8px;
}

.create-labels-link {
	margin:30px 0px 0px 0px;
}

#create-labels-add-sections-container {
	position: relative;
	min-height: 500px;
	padding:15px 30px 30px 30px;
	background-color: #fff;
	border-radius: 8px;
	box-shadow: 0 0 5px rgba(110, 103, 86, 0.15);
}

#create-labels-add-sections-container > h1 {
	display: inline-block;
	margin:0px 0px 20px 0px;
}

#create-labels-choose-department-dropdown {
	position: absolute;
    width: 250px;
    top: 20px;
    right: 30px;
}

#create-labels-sections-container {
	padding: 0px 20px 20px 20px;
	margin:0px 0px 20px 0px;
    min-height: 500px;
    max-height: 600px;
    overflow-y: auto;
    background-color: #f3f3f3;
}

.create-labels-sections-inner-container {
	display: grid;
	grid-template-columns: repeat(3, 1fr);
	column-gap:30px;
	row-gap:10px;
	margin:0px 0px 30px 0px;
}

.create-labels-department-heading {
	margin:15px 0px 0px 0px;
}

.create-labels-section-label {
	display: grid;
	grid-template-columns: auto 1fr auto;
	column-gap: 8px;
	align-items: center;
	margin:5px 0px 0px 0px;
	padding:3px 8px 3px 8px;
	border:1px solid #efefef;
	border-radius: 30px;
	font-size: 14px;
}

.add-label-to-section {
	margin: 10px 0px 0px 0px;
	text-align: right;
}

.create-labels-label-options-container {
	position: relative;
}

.create-labels-no-label-options {
	width: 90%;
    margin: 0px auto 10px auto;
    padding: 5px 15px 5px 15px;
    border: 1px dashed #ddd;
    border-radius: 5px;
    color: #a9a9a9;
    font-size: 12px;
}

.create-labels-label-option {
	display: grid;
	grid-template-columns: auto 1fr;
	column-gap: 12px;
	align-items: center;
}

/***************************/
/****** STUDENT LABELS *****/
/***************************/

#student-labels-main-container {
	position: relative;
	max-width: 900px;
	text-align: left;
}

#student-labels-header-container {
	display: grid;
	grid-template-columns: 1fr 1fr;
	column-gap: 60px;
	align-items: center;
	margin:0px 0px 5px 0px;
}

#student-labels-header-container > h1 {
	display: inline-block;
	margin:0px;
}

#student-label-department-dropdown {
	width: 200px;
	justify-self:right;
}

#student-label-department-dropdown > .dropdown-container {
	padding:10px;
	margin:0px 0px 0px 0px;
	/*background-color: #fafafa;*/
	border-color: #c4d0dc;
}

.student-labels-student-container {
	padding:10px 20px 20px 20px;
	margin:15px 0px 0px 0px;
	background-color: #fff;
	border-radius: 8px;
	border:1px solid #efefef;
	text-align: left;
	box-shadow: 0 0 5px rgba(110, 103, 86, 0.15);
}

.student-labels-student-container-heading {
	margin:0px 0px 3px 0px;
}

.student-labels-student-container-grade {
	color:#a9a9a9;
}

.student-labels-student-count {
	font-size: 14px;
	margin:0px 0px 15px 0px;
}

.student-labels-student-labels-parent-container {
	display: grid;
	grid-template-columns: 1fr 1fr;
	column-gap: 60px;
	margin:12px 0px 0px 0px;
}

.student-labels-no-label-options {
	background-color: #fff;
	border: 2px dashed #efefef;
    border-radius: 5px;
    padding: 3px 10px;
    color: #a9a9a9;
}

.student-labels-label-option {
	display: grid;
	grid-template-columns: auto 1fr auto;
	column-gap: 15px;
	align-items: center;
	margin:0px 0px 8px 0px;
	padding:3px 10px 3px 10px;
	border:1px solid #efefef;
	border-radius: 5px;
}

#student-labels-by-team-container {
	display: grid;
	grid-template-columns: 1fr 1fr;
	column-gap: 60px;
}

.student-labels-by-team-header {
	padding:0px 10px;
	margin:10px 0px 15px 0px;
	font-size: 30px;
	border-radius: 8px;
}

.student-labels-add-container {
	display: grid;
	grid-template-columns: 1fr;
	align-items: center;
	margin:20px 0px 5px 0px;
}

.student-labels-add-student-link {
	justify-self: end;
}

.student-labels-search-input {
	height: 36px;
    line-height: 36px;
    padding: 2px 0px 0px 40px;
}

.section-review-search-bar-container {
	position: relative;
	margin:18px 0px 0px 0px;
	/* width:70%; */
}

.section-review-search-bar {
	height:50px;
	padding: 10px 20px 10px 45px;
	border-radius: 12px;
	font-size: 15px;
}

.section-review-search-bar-icon {
	position: absolute;
	top:17px;
	left:12px;
	color: #ddd;
}

.section-review-search-bar:focus + .section-review-search-bar-icon {
	color: #2980b9 !important;
}































