#select-school-container {
	width: 100%;
	max-width: 1200px;
	margin:auto;
	padding:20px 20px 200px 20px;
	text-align: center;
}

#select-school-no-schools-container {
	width: 300px;
	margin:auto;
	padding:30px;
}

#add-new-school-container {
	width: 460px;
	margin:30px auto 0px auto;
	position: relative;
	padding:15px 15px 15px 20px;
	background-color: #fff;
	border-radius: 8px;
	box-shadow: 0 0 5px rgba(110, 103, 86, 0.15);
}
#add-new-school-btn-container {
	display: grid;
	grid-template-columns: 1fr 1fr;
	column-gap: 20px;
	margin:35px 0px 0px 0px;
}

.select-school-add-new-btn {
	justify-self: center;
	text-align: center;
}

#select-school-list {
	display: grid;
    grid-template-columns: repeat(auto-fit, minmax(100px, 250px));
    justify-content: center;
    grid-gap: 30px;
}

.select-school-container {
	position: relative;
    display: inline-block;
    padding: 40px 30px 15px 30px;
    background-color: #fff;
    border-radius: 8px;
    box-shadow: 0 0 5px rgba(110, 103, 86, 0.15);
    cursor: pointer;
}

.select-school-container:hover {
	background-color: #fbfbfb;
}

.select-school-more-options {
	position:absolute;
	top:10px;
	right:10px;
}

.select-school-more-options-container {
	position: relative;
}

.select-school-more-options-container > .data-options-container {
	min-width: 200px;
	right:0px;
	left: auto;
}

.select-school-name {
	position: relative;
	min-height: 90px;
	font-weight: 500;
}

.select-school-name-editing {
	display: grid;
    grid-template-columns: 1fr;
    grid-column-gap: 15px;
    /* min-height: 90px; */
    margin: 20px 0px 50px 0px;
}

.school-selected-check {
	position: absolute;
	top:10px;
	left:10px;
	font-size: 30px;
}

.delete-school-link {
	margin:10px 0px 0px 0px;
}

.select-school-edit-name-icon {
	position: absolute;
	top:0px;
	right:-20px;
	font-size: 14px;
}

.select-school-editing-btn-container {
	display: grid;
    grid-template-columns: 1fr 1fr;
    justify-items: center;
    margin: 10px 20px 0px 20px;
}

.select-school-school-type {
	margin:15px 0px 0px 0px;
}