.specials-setup-empty-courses, .pullouts-setup-empty-courses {
    background-color: #efefef;
    padding:20px;
    border:2px solid #ddd;
}

.specials-setup-option-container, .pullouts-setup-option-container {
    margin:0px 0px 0px 0px;
}

.specials-setup-course-option, .pullouts-setup-course-option {
    display: inline-block;
    padding:8px 15px;
    background-color: #fff;
    border:2px solid #ddd;
    border-radius: 5px;
    margin:0px 0px 5px 15px;
    cursor: pointer;
}

.specials-setup-course-option:first-child, .pullouts-setup-course-option:first-child {
    margin:0px;
}

.specials-setup-course-option:hover, .pullouts-setup-course-option:hover {
    background-color: #efefef;
}

.specials-setup-course-option-selected, .pullouts-setup-course-option-selected {
    border: 2px solid #2980b9 !important;
    background-color:#3498db !important;
    color: #fff !important;
}

.specials-setup-specials-container, .pullouts-setup-pullouts-container {
    width: fit-content;
    min-width: 750px;
    background-color: #fff;
    padding:36px 50px 50px 36px;
    border-radius: 10px;
    margin-top: 30px;
}

.specials-setup-question-container, .pullouts-setup-question-container {
    display: grid;
    margin:30px 0px 0px 0px;
}

.specials-setup-question-container:first-child, .pullouts-setup-question-container:first-child {
    margin:0px;
}

.specials-setup-icon, .pullouts-setup-icon {
    margin:3px 0px 0px 0px;
}

.specials-setup-special-name, .pullouts-setup-pullout-name {
    background-color: #f1f3f4;
    padding:5px 10px;
    border-radius: 5px;
    font-size: 28px;
}

.specials-setup-display, .specials-setup-specials-container .dropdown-container {
    width: 50%;
    display: grid;
    align-items: center;
    margin:0px 0px 6px 0px;
    height:40px;
    font-size: 14px;
    border:0px;
}

.specials-setup-label, .pullouts-setup-label {
    margin:0px 0px 5px 0px;
    font-size: 16px;
    font-weight: bold;
}

.specials-setup-name, .pullouts-setup-name {
    margin-top: 0px !important;
}

.specials-setup-display, .pullouts-setup-display {
    width: 50%;
    height:40px;
    display: grid;
    grid-template-columns: 1fr auto;
    column-gap: 30px;
    align-items: center;
    font-size: 14px;
    background-color: #f1f3f4;
    border-radius: 5px;
    padding:0px 10px;
    border:0px;
    margin:0px 0px 6px 0px;
}

.specials-setup-specials-container .dropdown-display, .pullouts-setup-pullouts-container .dropdown-display {
    font-size: 18px;
}

.specials-setup-add-link, .pullouts-setup-add-link {
    display: grid;
    grid-template-columns: auto 1fr;
    column-gap: 10px;
    margin:6px 0px 0px 0px;
    align-items: center;
}

.specials-setup-tct, .pullouts-setup-tct {
    display: flex;
    column-gap: 12px;
    align-items: center;
}

.pullouts-setup-group-container {
    position: relative;
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap:60px;
    padding: 30px;
    background-color: #f1f3f4;
    border-radius: 10px;
    margin:20px 0px 6px 0px;
}

.pullouts-setup-group-container-main-heading {
    font-size: 16px;
    font-weight: 600;
    text-decoration: underline;
    margin:0px 0px 12px 0px;
}

.pullouts-setup-group-label {
    font-size: 16px;
    font-weight: 200;
    margin:24px 0px 3px 0px;
}

.pullouts-setup-group-container .dropdown-container {
    background-color:#fff !important;
    height:40px;
}

.pullouts-setup-group-student-list {
    background-color: #fff;
    min-height: 340px;
    max-height: 424;
    overflow-y: auto;
    border-radius: 6px;
    margin:15px 0px 0px 0px;
    padding:10px 0px;
    border:2px solid #efefef;
}

.pullouts-setup-student-add-container {
    display: grid;
    grid-template-columns: 1fr auto;
    column-gap: 30px;
    align-items: center;
    margin:12px 0px 0px 0px;
}

.pullouts-setup-student-total {
    font-size: 18px;
    font-weight: 600;
}

.pullouts-setup-add-student-link {
    display: grid;
    grid-template-columns: auto 1fr;
    column-gap: 10px;
    align-items: center;
}

.pullouts-setup-group-student {
    width: 100%;
    display: grid;
    grid-template-columns: 1fr auto;
    column-gap: 30px;
    align-items: center;
    font-size: 14px;
    padding:5px 20px;
}

.pullouts-setup-group-delete {
    position:absolute;
    top:10px;
    right:10px;
    font-size: 18px;
}

/***********************************************************************/
/***********************************************************************/
/******************** ELEMENTARY DAY PERIOD RESTRICTIONS ********************/
/***********************************************************************/
/***********************************************************************/

.mb-elem-teacher-schedule-mini {
    width: 100%;
    padding: 12px 0px 18px 0px;
    background-color: #fff;
}

.mb-elem-week-schedule-container-mini {
	display: grid;
    grid-template-columns: auto 1fr;
    column-gap: 10px;
	margin: 0px 0px 0px 0px;
}

.mb-elem-week-schedule-time-container-mini {
	display: grid;
    align-items: start;
    font-size: 12px;
    padding: 10px 0px;
	margin:25px 0px 0px 0px;
}

.mb-elem-time-delimiter-mini {
	display: grid;
	height:72px;
	border-top: 2px dotted #ddd;
}

.mb-elem-week-schedule-days-container-mini {
	display: grid;
}

.mb-elem-week-day-container-mini {
	position: relative;
	text-align: center;
}

.mb-elem-week-day-container-mini h3 {
    margin-top: 0px;
    font-size: 14px;
}

.mb-elem-week-day-block-parent-container-mini {
    position: relative;
    height: 648;
    background-color: #f1f3f4;
    border-right: 1px solid #ddd;
    border-top: 0;
    border-bottom: 0;
}

.mb-elem-week-day-grid-mini {
	display: grid;
	width: 100%;
	height:700px;
	grid-template-columns: repeat(120, 1fr);
	grid-template-rows: repeat(576, 1fr);
}

.mb-elem-add-block-container-mini {
	position: absolute;
    bottom: 12px;
    left: 15px;
    display: grid;
    grid-template-columns: auto 1fr;
    grid-column-gap: 10px;
    column-gap: 10px;
    align-items: center;
    font-size: 20px;
}

.mb-elem-block-mini {
	display: grid;
}

.mb-elem-block-inner-mini {
	display: grid;
    grid-template-rows: auto auto auto 1fr;
    grid-template-columns: auto 1fr;
    column-gap: 8px;
    font-size: 12px;
    text-align: left;
    padding: 2px 0px 2px 4px;
    background-color: #fff;
    border-radius: 0px 5px 5px 0px;
	border:1px solid #eee;
}

.mb-elem-block-header-mini {
	position: relative;
    display: grid;
    grid-template-columns: 1fr auto;
    grid-column-gap: 10px;
    column-gap: 10px;
    align-items: center;
    border-radius: 5px 5px 0px 0px;
    min-width: 0;
	padding:0px 5px 0px 0px;
}

.mb-elem-block-name-mini {
	font-weight: bold;
	font-size: 13px;
}

.mb-elem-block-content-mini {
	position: relative;
    border-radius: 0px 0px 5px 5px;
    background-color: #fff;
    min-width: 0;
}

.mb-elem-block-time-mini {
	min-width: 0;
}