#documentation-main-container {
	display: grid;
}

#documentation-sidebar {
    position: fixed;
    height: 100%;
	width: 220px;
	background-color: #fff;
	padding:0px 0px 200px 0px;
	overflow-y: auto;
}

.documentation-sidebar-btn {
	position: relative;
    display: grid;
    align-items: center;
    padding: 15px 15px 15px 28px;
    font-size: 12px;
    color: #2c3e50;
    cursor: pointer;
}

.documentation-sidebar-btn:not(.documentation-sidebar-btn-selected):hover {
	background-color: #f8f8f8;
}

.documentation-sidebar-btn-selected {
	background-color: #34495e;
	color:#fff;
}

#documentation-main-content {
	padding:0px 0px 0px 220px;
}

#documentation-main-content-inner {
	padding:30px 60px;
}

.documentation-page {
	max-width: 800px;
}

.documentation-page p {
	font-size: 16px;
}

.step-main-heading {
	font-size:40px;
}

.choose-topic-btn, .sis-type-btn {
	width: 100%;
    padding: 5px;
    text-align: center;
    border: 2px solid #efefef;
    border-radius: 5px;
    background-color: #fff;
    cursor: pointer;
}

.choose-topic {
	display: grid;
    max-width: 800px;
    min-width: 640px;
    grid-template-columns: 1fr 1fr 1fr;
    justify-items: center;
    align-items: center;
    grid-column-gap: 30px;
    grid-row-gap: 10px;
}

.choose-topic-btn:hover, .sis-type-btn:hover, .choose-topic-btn-selected, .sis-type-btn-selected {
	background-color:#2980b9;
	color:#fff;
}

.documentation-container {
	width: 100%;
    position: relative;
    padding: 10px 50px 30px 50px;
    margin: 30px 0px 100px 0px;
    background-color: #fff;
    border-radius: 10px;
    box-shadow: 0 0 5px rgba(110,103,86,.15);
}

.documentation-container video {
	margin:10px 0px 10px 0px;
}

.documentation-screen-image {
	width:100%;
	border-radius: 10px;
}

.documentation-container-text-heading {
	margin:30px 0px 0px 0px;
	font-weight: bold;
}

.documentation-step-btn-container {
	display: grid;
    grid-template-columns: repeat( auto-fit, minmax(250px, 250px) );
    grid-column-gap: 30px;
    margin: 40px 0px 0px 0px;
    justify-content: center;
}

.documentation-goal-container {
	display: grid;
    grid-template-columns: auto 1fr;
    grid-column-gap: 30px;
    align-items: center;
    padding: 20px;
    border: 1px solid #efefef;
    border-radius: 8px;
    margin: 20px 0px 30px 0px;
}

/***************************/
/***** GETTING STARTED *****/
/***************************/
#getting-started-image-container {
	text-align: center;
    margin: 0px 0px 30px 0px;
}

#getting-started-image {
	width: 100%;
	border-radius: 10px;
	box-shadow: 0 0 5px rgba(110, 103, 86, 0.15);
}

/***********************/
/***** UPLOAD DATA *****/
/***********************/
.documentation-image {
	display: block;
	width:100%;
	max-width:900px;
	margin: 30px auto 30px auto;
	background-color: #fff;
}

.document-step-main-heading {
	text-align: left;
	margin:0px 0px 20px 0px;
	color:#c1c1c1;
	font-size:24px;
}

.documentation-heading {
	text-align: left;
	font-size:40px;
	margin:10px 0px 40px 0px;
	color:#2980b9;
	/*border-left:3px solid #3498db;
	padding:5px 0px 5px 10px;
	background-color: #fff;*/
}

.documentation-heading > span {
	color:#000;
}

.documentation-step-page {
	width: 100%;
	position: relative;
	padding: 20px 50px 40px 50px;
	background-color: #fff;
	margin:40px 0px 100px 0px;
	box-shadow: 0 0 5px rgba(110,103,86,.15);
}

.documentation-step-page-current {
	display: block !important;
}

.documentation-step-page p {
	font-size:16px;
}

.documentation-contact-support-container {
	display: grid;
	grid-template-columns: auto 1fr;
	grid-column-gap: 30px;
	margin:30px 0px 30px 0px;
}

.documentation-contact-support-container img {
	height:120px;
}

.documentation-contact-support-container h4 {
	margin:10px 0px 0px 0px;
}

#show-all-data-container {
	display: grid;
	grid-template-columns: 1fr;
	grid-column-gap: 30px;
	grid-row-gap: 15px;
}

.show-all-data-file-container {
	padding: 15px 20px 8px 40px;
    border: 3px solid #efefef;
    border-radius: 8px;
}

.show-all-data-heading {
	display: grid;
	grid-template-columns: auto 1fr;
	grid-column-gap: 15px;
	align-items: center;
}

.show-all-data-file-icon {
	font-size: 20px;
}

/**********************/
/***** CLEAN DATA *****/
/**********************/

.documentation-clean-data-screen-container {
	display: grid;
	grid-template-columns: auto 1fr auto;
	grid-column-gap: 30px;
	align-items: center;
	padding:8px 10px 8px 10px;
	border:1px solid #efefef;
	border-radius: 8px;
	margin:0px 0px 8px 0px;
	cursor:pointer;
}

.documentation-clean-data-screen-container:hover {
	background-color: #fdfdfd;
}

.documentation-clean-data-screen-label {
	width:20px;
	height:20px;
	border-radius: 20px;
}

.documentation-clean-data-example-container {
	padding: 15px 20px 15px 20px;
    border: 1px solid #efefef;
    border-radius: 8px;
    margin: 8px 0px 8px 0px;
}





















