#section-placement-main-container {
	width: 100%;
    margin: 0px auto 0px auto;
    padding: 20px 20px 200px 20px;
    text-align: center;
}

.section-placement-breadcrumb-container {
	max-width:800px;
	margin:auto;
}

#section-placement-department-list {
    width: 700px;
    padding: 0px 20px 20px 20px;
    border-radius: 10px;
    background-color: #fff;
    box-shadow: 0 0 5px rgb(110 103 86 / 15%);
}

.section-placement-add-supervisor {
	margin:0px 0px 0px 10px;
}

.section-placement-content {
	display: grid;
	grid-template-columns: auto 1fr;
	column-gap: 20px;
}

/**********************************/
/***** PLACEMENT PERMISSIONS *****/
/*********************************/
.placement-permissions-department-row {
	display: grid;
    grid-template-columns: 2fr 1fr;
    grid-column-gap: 15px;
    column-gap: 15px;
    padding: 24px 0px;
    text-align: left;
    align-items: center;
}

.section-placement-department {
	font-size: 18px;
}

.supervisors-container {
	display: grid;
	row-gap: 6px;
}

.placement-permissions-supervisor-container {
	display: grid;
	grid-template-columns: 200px 145px;
	column-gap: 20px;
	align-items:center;
}

.placement-permissions-toggle-container {
	justify-self: center;
}

/*****************************/
/***** MANUAL PLACEMENTS *****/
/*****************************/
#manual-placement-container {
	width: 100%;
	min-width: 700px;
    max-width: fit-content;
    margin: -16px 0px 0px 0px;
}

#manual-placement-magnetboard-container, #manual-placement-top-bar-container {
	display: grid;
	grid-template-columns: 1fr 200px;
	margin:0px 0px 20px 0px;
	/*column-gap: 30px;*/
}

.manual-placement-top-bar-inner-container {
	display: grid;
    grid-template-columns: 1fr auto;
    align-items: center;
	padding-right: 50px;
}

#manual-placement-back-to-departments {
	display: grid;
	grid-template-columns: auto 1fr;
	justify-items: left;
	column-gap: 10px;
	font-size: 14px;
}

#manual-placement-top-bar {
	/*background-color: #fff;*/
	position: relative;
	text-align: left;
	display: grid;
	grid-template-columns: auto 1fr;
	column-gap: 15px;
	padding:20px 0px 0px 0px;
    border-radius: 20px 20px 0px 0px;
}

#section-placement-submit-btn-container {
	display: grid;
    grid-template-columns: 1fr;
	max-height: 40px;
    /*position: absolute;
    top: 40px;
    right: 0px;*/
    justify-items: end;
}

#section-placement-submit-btn-container .gray-btn {
	max-width: 127px;
	margin:15px 0px 0px 0px;
}

#manual-placement-unassigned-sections {
	background-color: #fff;
	padding:10px 15px 10px 15px;
	width: 200px;
	height: 600px;
	position: sticky;
	top:30px;
	text-align: left;
	box-shadow: 0 0 5px rgba(110, 103, 86, 0.15);
	border-radius: 8px;
}

#mp-unassigned-sections-title {
	margin:0px 0px 10px 0px;
}

#mp-unassigned-sections-drop-area {
	background-color: #f8f8f8;
    height: 525px;
    border-radius: 8px;
    padding: 10px;
	overflow-y: auto;
}

.mp-unassigned-sections-drop-area-active {
	border:2px solid #ddd;
}

.section-placement-screen-message {
	width: 100%;
    display: grid;
    align-items: center;
    justify-items: center;
    text-align: center;
    padding: 60px 50px 70px 50px;
    margin: 30px auto 0px auto;
    border:2px dashed #efefef;
    border-radius: 10px;
    color: #2c3e50;
	background-color: #fff;
}

/*****************************/
/***** MANUAL PLACEMENTS *****/
/*****************************/

#placement-review-container {
	width: 100%;
    width:fit-content;
    margin: auto;
}

#placement-review-back-to-departments {
	display: grid;
	grid-template-columns: auto 1fr;
	justify-items: left;
	column-gap: 10px;
	font-size: 14px;
}

#placement-review-top-bar {
	/*background-color: #fff;*/
	position: relative;
	text-align: left;
	display: grid;
	grid-template-columns: auto 1fr;
	column-gap: 15px;
	padding:20px 20px 0px 20px;
    border-radius: 20px 20px 0px 0px;
}

/****************************/
/***** PLACEMENT REVIEW *****/
/****************************/
#generate-schedule-btn {
	width: fit-content;
	padding:15px 40px;
	margin: 40px auto 0px auto;
}

.placements-in-progress-container {
	width: 500px;
    background-color: #fff;
    border-radius: 10px;
    padding: 60px 40px 40px 40px;
    margin: auto;
    box-shadow: 0 0 5px rgb(110 103 86 / 15%);
}



