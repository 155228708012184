#upload-modal-content {
	width:930px;
	text-align: left;
	box-sizing: border-box;
	padding-bottom: 40px;
}

#upload-modal-title {
	margin-top:0px;
}

.next_upload_step {
	display: inline-block;
	width:280px;
	background-color:#27ae60;
	color:#fff;
	text-align:center;
	padding:15px;
	font-size: 20px;
	border-radius: 5px;
	cursor: pointer;
}

.next_upload_step:hover {
	background-color: #2ecc71;
}

.upload-skip-this-step-container {
    width:100%;
    background-color: #efefef;
    padding: 10px 100px 30px;
}

#upload-screen-main-container {
	width: 100%;
    max-width: 1200px;
    margin: 0px auto 0px auto;
    padding: 20px 20px 200px 20px;
    text-align: center;
}

#upload-screen-dashboard {
	width: 100%;
    max-width: 1200px;
    min-width: 860px;
	min-height: 600px;
    display: grid;
    grid-template-columns: 270px 1fr;
    column-gap: 30px;
    margin: auto;
}

.upload-screen-sidebar-btn {
	display: grid;
    grid-template-columns: 1fr auto;
	align-items: center;
    column-gap: 35px;
    padding-right: 30px;
}

.upload-screen-explanation-container {
	padding:20px 20px 30px 20px;
	background-color: #fff;
	text-align: center;
}

.upload-screen-main-upload-screen {
	/*width:100%;*/
	/*background-color: #fff;*/
	margin:0px 0px 0px 0px;
}

.ms_login_heading {
	width:660px;
	margin:10px auto 20px auto;
}

.upload-screen-heading-text {
	margin:10px 0px 0px 0px;
}

.upload-screen-subheading-text {
	margin:0px 0px 0px 0px;
	font-size: 40px;
}

.upload-screen-description {
	margin:30px 0px 30px 0px;
}

.upload-screen-data-container {
    max-width: 480px;
	margin:25px auto 0px auto;
	display: grid;
    grid-template-columns: 50px 1fr 100px auto;
	column-gap: 30px;
	row-gap: 30px;
    justify-items: center;
    align-items: center;
}

.upload_screen {
	min-width: 800px;
}

.upload_screen_li {
    margin: 0px auto 30px auto;
}

.upload-info-image {
	height:40px;
	margin:0px 0px 10px 0px;
}

.upload-info-name {
    justify-self: left;
    text-align: left;
}

.upload-complete {
	font-size: 24px;
}

.upload-complete-text {
	font-size: 14px;
}

.upload-how-to-line {
	margin:0px 0px 0px 0px;
}

.info-icon {
	margin:0px 5px 0px 0px;
}

.upload-parent-container {
	width:100%;
	position: relative;
	background-color:#fff;
	padding:30px;
	margin:30px 0px 0px 0px;
	border-radius: 8px;
	text-align: left;
}

.upload-screen-close-data {
	position: absolute;
    top: 10px;
    right: 10px;
    font-size: 30px;
}

.upload-courses-department-row {
	position: relative;
	padding:15px 15px 15px 20px;
	background-color: #fff;
	margin:5px 0px 15px 0px;
	border-radius: 8px;
	box-shadow: 0 0 5px rgba(110, 103, 86, 0.15);
}

.upload-courses-department-inner-row {
	display: grid;
	grid-template-columns: 1fr auto auto;
	column-gap: 15px;
}

.upload-courses-department-icon {
	font-size: 16px;
}

.upload_type_container  {
	position: relative;
	width:100%;
	margin:5px auto 0px auto;
}

.upload_data {
	width:100%;
	height: 100%;
}

.drag_file_upload_container {
	position:relative;
	width:100%;
	padding:100px 20px 100px 20px;
	border: 1px dashed #3498db;
	background-color:#f5f5f5;
	color:#3498db;
	text-align:center;
	cursor: pointer;
}

.drag_file_upload_container:hover {
	border:1px solid #3498db;
	background-color: #f9f9f9;
}

.upload-icon {
	font-size: 30px;
	margin:0px 0px 20px 0px;
}

.drag_file_upload_container input[type=file] {
	font-size: 90px;
	position: absolute;
	left: 0;
	top: 0;
	bottom:0;
	opacity: 0;
	cursor: pointer !important;
}

.uploaded_file {
	width:75%;
	margin:auto;
	border:1px solid #cecece;
	padding:4px 10px 4px 10px;
	margin:10px auto 0px auto;
}

.uploaded-file-container {
	display: grid;
    grid-template-columns: 30px 1fr 20px;
    column-gap: 25px;
    align-items: center;
    margin:0px 0px 0px 0px;
	background-color: #efefef;
	padding:10px;
	border-radius: 5px;
}

.upload-file-subtitle {
	margin:25px 0px 12px 0px;
}

.upload-file-upload-type-container {
	max-width: 600px;
	display: grid;
    grid-template-columns: 50px 1fr;
    column-gap: 25px;
    justify-items: center;
    align-items: center;
    margin:0px 0px 15px 0px;
	background-color: #efefef;
	padding:15px;
	border-radius: 5px;
	cursor: pointer;
}

.upload-file-upload-type-container:hover {
	background-color: #ececec;
}


.upload-file-upload-type-radio {
	height:25px;
}

.upload-file-upload-type-title {
	margin:0px 0px 6px 0px;
	font-size: 18px;
}

.upload-file-schedule-select-container {
	max-width: 400px;
	display: grid;
    grid-template-columns: 30px 1fr;
    column-gap: 15px;
    align-items: center;
    margin:0px 0px 0px 0px;
	background-color: #efefef;
	padding:5px 5px 5px 15px;
	border-radius: 5px;
	cursor:pointer;
}

.upload-file-schedule-select-box {
	font-size: 20px;
}

.upload-file-schedule-select-box.fas-checkbox-unchecked {
	color:#cccccc !important;
}

.uploading-file-container {
	display: grid;
	grid-template-columns: 30px 1fr 200px;
	column-gap: 25px;
    align-items: center;
    margin:0px 0px 0px 0px;
	background-color: #efefef;
	padding:10px;
	border-radius: 5px;
}

.uploaded-file-progress-container {
	display: grid;
    grid-template-columns: 1fr auto;
    align-items: center;
    column-gap: 10px;
}

.uploaded-file-icon-container {
	display: flex;
    width: 100%;
    height: 100%;
}

.uploaded-file-icon {
	font-size: 60px;
	margin:8px 0px 0px 5px;
}

.uploaded-file-progress-bar-container {
	width:100%;
}
 
 .previous_sections_file {
	text-align: left;
	margin:0px 0px 5px 0px;
	max-width: 400px;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
 }

.progressor {
	/* Reset the default appearance */
	-webkit-appearance: none;
	appearance: none;
	width:100%;
	height:10px;
	
}

progress[value]::-webkit-progress-bar {
  background-color: #dddddd;
  border-radius: 8px;
}

progress[value]::-webkit-progress-value {
	background-color:#3498db;
	border-radius: 8px; 
}

.uploaded-file-name-container {
	width:100%;
	display:grid;
	grid-template-columns: 1fr;
	margin:5px 0px 0px 0px;
	align-items:center;
}

.uploaded-file-status-container {
	width:100%;
	margin:5px 0px 0px 0px;
	align-items:center;
}

.uploaded-file-results {
	text-align: left;
	padding:0px 0px 0px 5px;
	color:#a9a9a9;
	font-size: 12px;
}

.uploaded-file-percentage {
	text-align: right;
	color:#3498db;
	font-size: 30px;
}

.uploaded-file-btn-container {
	text-align: right;
}

.upload-file-btn {
    margin:80px 0px 0px 0px;
	box-sizing: border-box;
}

.upload-complete-icon-container {
	text-align: right;
}

.uploaded-file-remove-file {
	padding: 6px 0px 0px 0px;
	cursor: pointer;
}

.remove-file-icon {
	color:#a9a9a9;
	font-size: 20px;
}

.remove-file-icon:hover {
	color:#9a9a9a;
}

.cancel-upload-file {
	position: absolute;
	top:5px;
	right:10px;
	color:#ddd;
	cursor: pointer;
}

.cancel-upload-file:hover {
	color:#3498db;
}

.upload-screen-student-request-inner-row {
	display: grid;
	column-gap: 10px;
	align-items: center;
}

.upload-screen-request-list {
	margin: 15px 0px 30px 0px;
}

.upload-screen-request-list-header {
	margin:0px 0px 15px 0px;
}

.upload-screen-request-student {
	padding:5px;
}

.upload-screen-empty-requests {
	background-color: #efefef;
	padding:20px;
}

.upload-btn {
	max-width: 250px;
	margin:50px auto 30px auto;
}

.upload-screen-already-uploaded-container  {
	display: grid;
	grid-template-columns: auto 1fr;
	column-gap: 30px;
	background-color: #efefef;
    text-align: left;
    padding: 15px 30px;
    font-size: 16px;
    max-width: 500px;
    border: 2px solid #ddd;
    border-radius: 10px;
    margin: 40px auto 30px;
}

.upload-screen-already-uploaded-check {
	font-size: 40px;
}

.upload-screen-already-uploaded-header {
	margin:0px 0px 10px 0px;
	font-weight: bold;
}





