#fill-sections-main-container {
	width: 100%;
    margin: 0px auto 0px auto;
    padding: 20px 20px 200px 20px;
    text-align: center;
}

.fill-sections-screen-message {
	width: 100%;
    display: grid;
    align-items: center;
    justify-items: center;
    text-align: center;
    padding: 60px 50px 70px 50px;
    margin: 30px auto 0px auto;
    border:2px dashed #efefef;
    border-radius: 10px;
    color: #2c3e50;
	background-color: #fff;
}

#fill-sections-btn {
	width: fit-content;
	padding:15px 40px;
	margin: 40px auto 0px auto;
}

.placements-in-progress-container {
	width: 500px;
    background-color: #fff;
    border-radius: 10px;
    padding: 60px 40px 40px 40px;
    margin: auto;
    box-shadow: 0 0 5px rgb(110 103 86 / 15%);
}