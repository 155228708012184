
@keyframes slide-in{
    0%    {
        right: -380px;
        visibility: hidden;
    }
    100%  {  
        right:0;
        visibility: visible;
    }
}
@keyframes slide-out{
    0%    {
        right:0;  
        visibility: visible;
    }
    100%  {
        right:-380px; 
        visibility: hidden;
    }
}

.universal-sidebar-modal-screen {
    position: fixed;
    z-index: 9999;
    right: -380px;
    top: 0;
    width: 380px;
    height: 100%;
    background-color: #fff;
    overflow-y: auto;
    z-index: 99999 !important;
    box-shadow: -5px 0px 25px 1px #efefef;
    visibility: hidden;
}

.universal-sidebar-modal-screen.open{
    -webkit-animation: slide-in 200ms linear forwards;
    -moz-animation: slide-in 200ms linear forwards;
    -o-animation: slide-in 200ms linear forwards;
    -ms-animation: slide-in 200ms linear forwards;
    animation: slide-in 200ms linear forwards;
}
.universal-sidebar-modal-screen.close{
    -webkit-animation: slide-out 200ms linear forwards;
    -moz-animation: slide-out 200ms linear forwards;
    -o-animation: slide-out 200ms linear forwards;
    -ms-animation: slide-out 200ms linear forwards;
    animation: slide-out 200ms linear forwards;
}

.universal-sidebar-modal-screen-content {
    position: absolute;
    margin: 0px;
    border: none;
    width: 100%;
    top: 58px;
    bottom: 58px;
    overflow-y: auto;
    padding: 22px;
    z-index: 99999 !important;
}

.universal-sidebar-modal-close {
  width: fit-content;
  text-align: center;
  color: #fff;
  font-size: 28px;
  font-weight: bold;
  padding: 0px 10px 0px 10px;
  border-radius: 20px;
  margin: 0px 0px 0px 0px;
}

.universal-sidebar-modal-close:hover,
.universal-sidebar-modal-close:focus {
  color: black;
  text-decoration: none;
  cursor: pointer;
  background-color: #efefef;
}

#universal-sidebar-top-bar {
    display: grid;
    width:100%;
    grid-template-columns: auto 1fr;
    column-gap: 20px;
    align-items: center;
    padding:10px 20px 10px 10px;
}

#universal-sidebar-add-comment {
    display: grid;
    position: absolute;
    padding: 20px;
    border: 1px solid #efefef;
    align-items: center;
    justify-content: center;
    bottom: 10px;
    right: 10px;
    border-radius: 40px;
    font-size: 20px;
    cursor: pointer;
}

#universal-sidebar-add-comment:hover {
    font-size: 24px;
}

.universal-sidebar-note {
    position: relative;
    display: grid;
    row-gap: 12px;
    padding: 16px;
    border: 1px solid #ddd;
    border-radius: 5px;
    margin: 0px 0px 5px 0px;
}

.universal-sidebar-new-note {
    margin-bottom: 20px;
}

.universal-sidebar-new-note-parent-container {
    position: relative;
}

.universal-sidebar-new-note-textarea {
    min-height: 100px;
}

.universal-sidebar-new-note-at-user {
    display: grid;
    grid-template-columns: auto 1fr;
    grid-column-gap: 15px;
    column-gap: 15px;
    align-items: center;
    background-color: #efefef;
    padding: 5px;
    border: 2px solid #ddd;
    margin: 0px;
    margin-top: -10px;
}

.universal-sidebar-user-container {
    display: grid;
    grid-template-columns: auto 1fr;
    column-gap: 15px;
    align-items: center;
}

.universal-sidebar-note-user-img {
    height:35px;
}

.universal-sidebar-note-user-icon {
    font-size: 35px;
}

.universal-sidebar-user-name {
    display: grid;
    row-gap:5px;
}

.universal-sidebar-btn-container {
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 10px;
    align-items: center;
}

.universal-sidebar-delete-note {
    position:absolute;
    top:5px;
    right:5px;
    font-size: 14px;
    cursor: pointer;
}

.universal-sidebar-delete-note:hover {
    opacity: .9;
}

.universal-sidebar-tagged-users {
    display: grid;
    align-items: center;
    grid-column-gap: 15px;
    column-gap: 15px;
    row-gap: 5px;
}

.universal-sidebar-tagged-user {
    display: grid;
    grid-template-columns: 1fr 10px;
    grid-column-gap: 8px;
    grid-column-gap: 12px;
    column-gap: 12px;
    align-items: center;
    padding: 5px;
    border: 1px solid #ddd;
    width: -moz-fit-content;
    width: fit-content;
    font-size: 12px;
    border-radius: 15px;
    background-color: #efefef;
}

