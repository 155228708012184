#school-data-main-container {
	display: grid;
}

#school-data-sidebar {
    position: fixed;
    height: 100%;
	width: 220px;
	background-color: #fff;
	padding:0px 0px 200px 0px;
	overflow-y: auto;
	z-index: 100;
}

.school-data-sidebar-btn {
	position: relative;
	display: grid;
	grid-template-columns: auto 1fr auto;
	column-gap: 15px;
	align-items: center;
	padding:15px 15px 15px 18px;
	font-size: 12px;
	cursor: pointer;
}

.school-data-sidebar-btn:not(.school-data-sidebar-btn-selected):hover {
	background-color: #f8f8f8;
}

.school-data-sidebar-btn-selected {
	background-color: #34495e;
	color:#fff;
}

.school-data-sidebar-icon {
	font-size: 18px;
}


#school-data-main-content {
	padding:0px 0px 0px 220px;
}

#school-data-welcome-screen {
	text-align: center;
	opacity: 0.0;
	animation: fadeIn ease 3s forwards;
	-webkit-animation: fadeIn ease 3s forwards;
}

#school-data-welcome-screen-image {
	width: 600px;
	margin: 20px 0px -50px 0px;
}

.school-data-content-screen {
	width: 100%;
	min-width: 800px;
	max-width: 1200px;
	padding:40px 50px;
}

.school-data-main-heading {
	margin: 0px 0px 15px 0px;
	font-size: 40px;
}

.school-data-top-bar {
	display: grid;
	grid-template-columns: 1fr auto;
	align-items: center;
	column-gap: 100px;
}

.school-data-search-bar-container {
	position: relative;
	/* width:70%; */
}

.school-data-search-bar {
	height:40px;
	line-height:40px;
	padding: 10px 20px 10px 35px;
	border-radius: 20px;
	font-size: 15px;
}

.school-data-search-bar-icon {
	position: absolute;
	top:12px;
	left:12px;
	color: #ddd;
}

.school-data-search-bar:focus + .school-data-search-bar-icon {
	color: #2980b9 !important;
}

.school-data-database-display {
	position: relative;
	margin:10px 0px 0px 0px;
	box-shadow: 0 0 5px rgba(110,103,86,.15);
	background-color: #fff;
}

.school-data-database-row {
	position: relative;
	display: grid;
	padding:12px;
	background-color: #fff;
	column-gap: 10px;
	align-items: center;
}

.school-data-database-display-header {
	background-color: #34495e;
	color:#fff;
	font-size: 12px;
	border-radius: 6px 6px 0px 0px;
	padding:12px 10px 12px 10px;
}

.school-data-database-header-col {
	display: grid;
	align-items: center;
	grid-template-columns: auto 1fr;
	column-gap: 7px;
}

.school-data-database-row:not(.school-data-database-display-header):nth-child(odd) {
  background-color: #f7f7f7;
}

.school-data-database-row-btn-container {
	display: grid;
	grid-template-columns: 1fr 1fr;
	column-gap: 10px;
	align-items: center;
	justify-items:center;
}

.school-data-database-message-container {
	width: 100%;
	text-align: center;
	padding:10px;
}

.school-data-department {
	font-size: 14px;
	margin:0px 0px 0px 0px;
}

.school-data-department:not(:first-child) {
	margin:5px 0px 0px 0px;
}

.school-data-database-add-new-container {
	justify-self: right;
}

.school-data-database-add-new-btn {
	width: 40px;
    font-size: 18px;
    text-align: center;
    border-left: 1px solid #a9a9a9;
    padding: 0px 0px 0px 12px;
    cursor: pointer;
}

.school-data-student-requesting-row {
	display: grid;
	grid-template-columns: 30px 70px 2fr 2fr 2fr 50px;
	column-gap: 10px;
	align-items: center;
	padding:5px 5px 5px 12px;
}

.school-data-student-request-bulk-actions-container {
	display: grid;
	grid-template-columns: 1fr auto;
	align-items: center;
	margin:0px 0px 0px 0px;
}

.school-data-student-request-btn {
	max-width: 200px;
	font-size: 14px;
}










