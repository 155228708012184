#structure_setup_container {
	display:none;
	width:100%;
	padding: 10px 0px 20px 0px;
}

#edit_schedule_structure_container {
	position:relative;
	margin:40px 0px 0px 0px;
}

.time-select-container {
	display: grid;
	grid-template-columns: 1fr 1fr;
	grid-gap: 6px;
}

/*.time-select {
	width:100%;
}*/

.time-select-label {
	color: #efefef;
	text-align: center;
	font-size: 12px;
}

.time-select-input {
	width: 100%;
	border:2px solid #efefef;
	border-radius:4px;
	padding:2px 0px 2px 0px;
	font-size:14px;
	text-align:center;
	cursor:pointer;
}

.time-select-start-time:focus, .time-select-end-time:focus {
	border: 2px solid #2980b9 !important;
	outline: none !important;
}

