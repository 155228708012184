#signin_form {
	width:300px;
	margin:0px auto 0px auto;
	padding:30px 20px 30px 20px;
	background-color:#fff;
	border-radius:8px;
	box-shadow: 0 0 5px rgba(110,103,86,.15);
}

.error-border {
	border:1px solid red !important; 
}

.signin_header_container {
	position:relative;
	float:left;
	width:50%;
	height:50px;
	margin-bottom:25px;
	background-color:#516374;
	color:#fff;
	line-height:40px;
	text-align:center;
	cursor:hand;
	cursor:pointer;
}

.signin_header_container:hover {
	background-color:#617485;
}

#signin_inputs input, #signup_inputs input {
	display:block;
	margin: 0px 0px 10px 0px;
	width:100%;
	height:40px;
	line-height:40px;
	padding-left:5px;
	border:2px solid #efefef;
}

#signin_inputs input:focus, #signup_inputs input:focus {
	border-color: #2980b9;
}

.selected_bar {
	position:absolute;
	bottom:0px;
	width:100%;
	background-color:#fff;
	height:10px;
}

#signin_dropdown, #signup_dropdown {
	width:88%;
	margin:auto;
}

.login-options {
	width:300px;
	padding:10px 5px 0px 5px;
	margin:auto;
	font-size:13px;
	color:#a9a9a9;
}

.login-option:hover {
	cursor: pointer;
	cursor: hand;
	color:#000;
}

.login-header {
	margin:0px 0px 10px 0px;
	color:#2c3e50;
	text-align: center;
}

.login-text {
	margin:0px 0px 20px 0px;
	color:#7f8c8d;
	font-size: 14px;
	text-align: center;
}

.login-btn {
	font-family: "Nunito", sans-serif;
	margin:30px 0px 0px 0px;
}

.login-error {
	margin: 30px 0px -20px 0px;
}

.login-input-container {
	position: relative;
}

.login-toggle-hidden-icon {
	position: absolute;
	top:12px;
	right:12px;
}
