#clean-data-main-container {
	width: 100%;
    max-width: 1200px;
    margin: 0px auto 0px auto;
    padding: 20px 20px 200px 20px;
    text-align: center;
}

.clean-data-breadcrumb-container {
	width:620px;
	margin:auto;
}

#clean-data-dashboard {
	width: 100%;
    max-width: 1200px;
    min-width: 860px;
	min-height: 600px;
    display: grid;
    grid-template-columns: 270px 1fr;
    column-gap: 30px;
    margin: auto;
}

#clean-data-sidebar {
	height: fit-content;
	min-height: 600px;
	background-color: #2c3e50;
	text-align: left;
	padding: 0px 0px 20px 0px;
	border-radius: 8px;
	box-shadow: 0 0 5px rgba(110, 103, 86, 0.15);
}

#clean-data-sidebar-alternative {
	position: relative;
	background-color: #fff;
	text-align: left;
	padding: 0px 0px 20px 0px;
	border-radius: 8px;
	box-shadow: 0 0 5px rgba(110, 103, 86, 0.15);
	height: fit-content;
}

.clean-data-sidebar-btn {
	position: relative;
	width:100%;
	padding:20px 20px 20px 40px;
	font-size: 14px;
	color:#8ba7c4;
	cursor: pointer;
}

.clean-data-sidebar-btn:not(.clean-data-sidebar-btn-selected):hover {
	background-color:#34495e;
	color:#efefef;
}

.clean-data-sidebar-icon {
	margin:0px 14px 0px 0px;
}

.clean-data-sidebar-btn-selected {
	background-color:#2980b9;
	color:#fff;
}

#clean-data-sidebar > .clean-data-sidebar-header {
	width:100%;
	padding: 25px 0px 20px 25px;
	font-size: 14px;
	font-weight: bold;
	color:#fff;
}

#clean-data-sidebar-alternative > .clean-data-sidebar-header {
	width: 100%;
    text-align: left;
    padding: 15px 20px 20px 8px;
    font-size: 18px;
    font-weight: bold;
    color: #2c3e50;
}

.clean-data-duplicate-btn-label {
	position: relative;
	display: inline-block;
}

.clean-data-duplicate-counter {
	position: absolute;
    display: flex;
    top: 2px;
    right: -33px;
    width: 25px;
    height: 18px;
    justify-content: center;
    align-items: center;
    border-radius: 12px;
    font-size: 12px;
	background-color:#efefef;
	color:#a9a9a9;
}

.clean-data-duplicate-button-selected .clean-data-duplicate-counter {
	background-color:#2980b9 !important;
	color:#fff !important;
}

.clean-data-sidebar-btn-selected > .clean-data-duplicate-counter {
	background-color:#fff;
	color:#2980b9;
}

#clean-data-sidebar-alternative > .clean-data-sidebar-header-padding {
	width: 100%;
    text-align: left;
    padding: 15px 20px 20px 20px;
    font-size: 18px;
    font-weight: bold;
    color: #2c3e50;
}

.clean-data-back-to-departments {
	margin: 0px 13px 0px 0px;
    color:#bfbfbf;
    cursor: pointer;
}

.clean-data-back-to-departments:hover {
	color: #2c3e50;
}

.clean-data-department-complete-check {
	position: absolute;
	display: flex;
	top:22px;
	right:15px;
	font-size:20px;
}

.clean-data-sidebar-btn-selected > .clean-data-department-complete-check {
	color:#95d6b0 !important;
}

#clean-data-content {
	display: grid;
	grid-template-columns: auto 1fr;
	width: fit-content;
	min-width: 700px;
	max-width: 1200px;
	position: relative;
	background-color:#fff;
	border-radius: 10px;
	box-shadow: 0 0 5px rgba(110, 103, 86, 0.15);
	text-align: left;
	margin:-16px 0px 0px 0px;
}

.clean-data-content-inner-content {
	padding:20px 30px;
}

.clean-data-add-supervisor-link {
	margin:0px 0px 0px 10px;
}

.clean-data-submit-btn-container {
	display: grid;
	grid-template-columns: 1fr;
	position: absolute;
	top:25px;
	right:20px;
	justify-items: end;
}

.clean-data-submit-btn-container .gray-btn {
	max-width: 127px;
	margin:0px;
}

.clean-data-content-department-header {
	margin:0px 0px 5px 0px;
	color: #a9a9a9;
}

.clean-data-content-header-container {
	position: relative;
	display: inline-grid;
	grid-template-columns: auto auto;
	column-gap: 20px;
	margin:0px 200px 10px 0px;
	align-items: center;
}

.clean-data-content-header {
	margin:0px 0px 0px 0px;
	color: #2c3e50;
}

.clean-data-status {
	display: grid;
    grid-template-columns: auto 20px;
    column-gap: 7px;
    align-items: center;
    padding: 5px 0px 0px 0px;
}

#clean-data-submit-btn-container {
	position: absolute;
	top:0px;
	right:0px;
}

.clean-data-supervisor-inner-container {
	float:left;
	display: grid;
	grid-template-columns: 35px 1fr;
	margin:6px 10px 0px 0px;
	align-items: center;
	border:1px solid #ddd;
	border-radius: 20px;
	padding:6px 10px 6px 10px;
}

.clean-data-supervisor-user-icon {
	font-size: 25px;
}

.clean-data-supervisor-img {
	width:25px;
	height:25px;
	border-radius: 20px;
}

.clean-data-department-supervisor {
	font-size: 14px;
}

.clean-data-no-data-container {
	padding: 30px 20px 30px 20px;
	margin:0px auto 0px auto;
	border:3px dashed #efefef;
	border-radius:5px;
	text-align: center;
	font-size: 24px;
	color:#2c3e50;
}

.clean-data-no-data-icon {
	font-size: 34px;
	margin:0px 0px 10px 0px;
}

.clean-data-no-data-container > p {
	font-size: 14px;
}

.period-availability-checkmark {
	font-size: 18px;
    justify-self: center;
}

.clean-data-other-option-heading {
	display: grid;
	grid-template-columns: 1fr auto;
	column-gap: 10px;
	align-items: center;
}

.clean-data-more-info-icon {
	cursor: pointer;
}

.clean-data-more-info-icon:hover {
	color:#2c3e50;
}

.clean-data-popup {
	width:200px;
	top:40px;
	right:0px;
}

.clean-data-popup-separator {
	height: 2px;
    width: 100%;
    background-color: #2980b9;
}

.clean-data-connection-main-container {
	margin: 30px 0px 0px 0px;
    /* padding: 15px 20px 15px 40px; */
    border: 1px solid #efefef;
    border-radius: 8px;
    box-shadow: 0 0 5px rgba(110, 103, 86, 0.15);
}

.clean-data-connection-main-header {
	display: grid;
	grid-template-columns: 1fr auto;
	align-items: center;
    margin: 0px 0px 0px 0px;
    background-color: #efefef;
    color: #2c3e50;
    padding: 6px 15px 6px 15px;
    border-radius: 8px 8px 0px 0px;
}

.clean-data-connection-main-container-inner {
	padding:17px 26px 10px 26px;
}

.clean-data-connection-container {
	position: relative;
    display: grid;
    width: 100%;
    padding: 10px 0px 10px 20px;
    margin: 0px 10px 10px 0px;
    align-items: center;
    border: 1px solid #efefef;
    border-radius: 8px;
}

.clean-data-connection-header {
	margin:0px 0px 0px 0px;
}

.clean-data-clear-heading {
	color:#888888;
	border-bottom:1px solid #efefef;
	border-radius:0;
	padding:5px 0px 5px 0px;
}

.clean-data-connection-icon {
	font-size: 16px;
	margin:0px 10px 0px 0px;
}

.clean-data-connection-label {
	font-size: 12px;
	color:#a9a9a9;
}

.clean-data-remove-connection-container {
	justify-self:right;
	align-self: center;
	padding:2px 15px 0px 0px;
}

.clean-data-add-new-connection {
	width: 100%;
    text-align: right;
    padding: 16px 5px 8px 0px;
    font-size: 14px;
}

/**** CLEAN DATA CSS ****/
#skip-data-cleaning, #skip-upload-requests {
	color:#2980b9;
	cursor: pointer;
}

.clean-data-breabcrumb {
	display: grid;
    grid-template-columns: 40px 1fr 30px;
    width: 100%;
    padding: 10px 10px 10px 20px;
    align-items: center;
    color: #a9a9a9;
    cursor: pointer;
}

.clean-data-breabcrumb:hover {
	color:#2c3e50 !important;
}

.clean-data-breabcrumb-selected {
	color:#2c3e50 !important;
	background-color: #f6f6f6;
}

.clean-data-breabcrumb-number {
	display: flex;
    height: 30px;
    width: 30px;
    border-radius: 15px;
    background-color: #dcdcdc;
    font-size: 12px;
    justify-content: center;
    align-items: center;
}

.clean-data-breabcrumb-name {
	margin:4px 0px 0px 10px;
	font-size:12px;
}

.clean-data-breabcrumb-selected > .light-gray-text {
	color: #dddddd !important;
}

.clean-data-main-heading {
	position:relative;
	width:100%;
	/*background-color:#fff;*/
	font-size:45px;
	padding:5px 0px 5px 0px;
	margin:20px 0px 0px 0px;
	border-radius:5px;
	color:#a9a9a9;
}

.clean-data-container {
	width:100%;
	padding:0px 0px 0px 0px;
	margin:25px 0px 0px 0px;
}

.clean-data-duplicate-container {
	width: 100%;
    max-height: 800px;
    padding: 0px 20px 50px 10px;
    margin: 25px 0px 0px 0px;
    overflow-y: auto;
}

#clean-data-duplicate-text {
	display: grid;
	grid-template-columns: 2fr 1fr;
	column-gap: 20px;
	align-items: center;
	margin:10px 0px 30px 0px;
}

.clean-data-heading {
	display: grid;
	position:relative;
	width:100%;
	height: 100%;
	background-color:#efefef;
	color:#34495e;
	padding:2px 5px 2px 5px;
	margin:0px 0px 0px 0px;
	border-radius:5px;
	text-align: center;
	align-items: center;
}

.clean-data-progress-checkbox {
	position: absolute;
	right:7px;
	top:7px;
	cursor: pointer;
}

.clean-data-row {
	display: grid;
	width:100%;
	padding:10px 0px 10px 0px;
	align-items: center;
}

.clean-data-header-row, .clean-data-select-row {
	display: grid;
	width:100%;
	margin:0px 0px 5px 0px;
}

.clean-data-select-row {
	margin:5px 0px 5px 0px;
	justify-items: center;
}

.clean-data-add-row-container {
	width: 100%;
	display: grid;
	grid-template-columns: 1fr 40px 40px;
	align-items: center;
	padding:15px;
	margin:10px 0px 0px 0px;
	border: 2px solid #efefef;
	border-radius:5px;
	background-color: #fff;
}

.clean-data-cancel-new-btn, .clean-data-add-new-btn {
	justify-self:right;
}

.clean-data-col {
	position: relative;
	margin:0px 5px 0px 0px;
}

#clean-data-duplicate-main-container {
	max-width: 800px;
	margin:auto;
	padding:20px;
	background-color:#fff;
	border-radius: 8px;
	box-shadow: 0 0 5px rgba(110, 103, 86, 0.15);
}

.clean-data-duplicates-container {
	width: 100%;
    max-height: 800px;
    padding: 20px 20px 50px 10px;
    /* margin: 25px 0px 0px 0px; */
    overflow-y: auto;
}

.clean-data-content-duplicates-header-container {
	margin:0px 0px 10px 0px;
}

.clean-data-instructions-container {
	/*border:2px solid #efefef;
	border-radius: 8px;*/
	padding: 20px 30px 20px 30px;
	margin: 0px 0px 40px 0px;
	border:1px solid #efefef;
	border-radius: 10px;
	box-shadow: 0 0 5px rgba(110, 103, 86, 0.15);
}

.clean-data-instructions-option {
	position: relative;
	height: 100%;
	padding:20px;
	font-size: 14px;
	text-align: left;
}

.clean-data-instructions-option > h3, .clean-data-instructions-option > h4{
	margin:0px;
	font-size: 18px;
}

.clean-data-instructions-img {
	height:80px;
	margin:20px 0px 10px 0px;
}

.clean-data-btn-img {
	height:30px;
	vertical-align:middle;
}

.clean-data-duplicate-button-container {
	display: grid;
	grid-template-columns: repeat(4, 1fr);
}

.clean-data-duplicate-button {
	position: relative;
	padding:10px;
	text-align: center;
	color:#999;
	cursor: pointer;
}

.clean-data-duplicate-button:hover {
	color:#34495e;
}

.clean-data-duplicate-button-selected {
	color:#2c3e50;
	border-bottom: 3px solid #2980b9;
}

.duplicate-group-container {
	padding:20px 20px 20px 20px;
	margin:0px 0px 20px 0px;
	border:1px solid #efefef;
	border-radius: 8px;
	background-color: #fff;
	box-shadow: 0 0 5px rgba(110, 103, 86, 0.15);
}

/* CSS FOR TRANSITION ANIMATIONS */
.duplicategroup-appear {
  opacity: 1;
}

.duplicategroup-appear-active {
  opacity: 1;
}

.duplicategroup-appear-done {
	opacity: 1;
}

.duplicategroup-enter {
  opacity: 1;
}

.duplicategroup-enter-active {
  opacity: 1;
}

.duplicategroup-enter-done {
	opacity: 1;
}

.duplicategroup-exit {
  opacity: 1;
}

.duplicategroup-exit-active {
  animation-name: fadeOutRight;
  animation-duration: 500ms;
}

.duplicategroup-exit-done {
	opacity: 0;
	transform: translate3d(100%, 0, 0);
}
/* END OF CSS FOR TRANSITION ANIMATIONS */

.duplicate-group-mark-as-okay {
	display: grid;
    width: 100%;
    align-items: right;
    justify-items: right;
}

.duplicate-group-mark-as-okay-btn {
	display: grid;
    grid-template-columns: 1fr 20px;
    column-gap: 5px;
    align-items: center;
    justify-items: center;
    width: -moz-fit-content;
    width: fit-content;
    padding: 5px 5px 5px 10px;
    margin: 0px 0px 15px 0px;
    text-align: center;
    border: 1px solid #b9b9b9;
    border-radius: 5px;
    color: #2c3e50;
    font-size: 12px;
    cursor: pointer;
}

.duplicate-group-mark-as-okay-btn:hover {
	color:#27ae60;
	background-color:#f0fff6;
    border: 1px solid #27ae60;
}

.duplicate-group-row-header {
	display: grid;
	column-gap: 5px;
	padding: 0px 5px 0px 12px;
	margin:0px 0px 10px 0px;
	align-items: center;
	justify-items: center;
	font-size: 12px;
	color:#000;
}

.duplicate-group-row {
	display: grid;
	column-gap: 5px;
	padding: 8px 5px 8px 12px;
	border:1px solid #efefef;
	border-radius: 5px;
	margin:0px 0px 5px 0px;
	align-items: center;
	justify-items: center;
	font-size: 14px;
}

.duplicate-group-row-deleted {
	background-color: #efefef;
    color: #a9a9a9 !important;
    border: 1px solid #ddd;
}

.clean-data-loader {
	height: 40px;
}

/**** END OF CLEAN DATA CSS ****/
.clean-data-max-capacity-row {
	display:grid;
	grid-template-columns: 300px 1fr;
	column-gap: 5px;
	margin:0px 0px 15px 0px;
}

.clean-data-max-capacity-container {
	padding:10px;
	box-shadow: 0 0 5px rgba(110, 103, 86, 0.15);
	border-radius: 8px;
}

.clean-data-add-new-data-text {
	padding: 40px 0px 0px 10px;
    margin: 0px 0px 0px 0px;
}

.clean-data-add-new-data {
	width: 100%;
    text-align: right;
    padding: 30px 15px 8px 0px;
    font-size: 14px;
}