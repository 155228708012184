.main-mb-content {
	padding:30px 50px 100px 50px;
}

.mb-container {
	min-width: 1000px;
}

.mb-top-bar {
	width: 100%;
    padding: 20px 0px 18px 0px;
    border-radius: 20px 20px 0px 0px;
    border-bottom: none;
    /*background-color: #fff;*/
}

.mb-top-bar-inner {
	display: grid;
	grid-template-rows: 1fr;
	row-gap: 15px;
}

.mb-top-bar-row {
	display: grid;
    grid-template-columns: auto auto 1fr;
    column-gap: 15px;
    align-items: center;
}

.mb-top-bar-label {
	font-size: 12px;
	text-align: left;
	/*color:#a9a9a9;
	font-weight: bold;*/
}

.mb-schedule-name-container {
	position: relative;
	cursor: text;
}

.mb-schedule-name {
	display: inline-block;
    margin: 0px;
    padding: 0px 30px 0px 10px;
    border: 2px solid #f5f7fa;
    border-radius: 8px;
    min-width: 400px;
    min-height: 47px;
}

.mb-schedule-name:not(.mb-schedule-name-no-hover):hover {
	border: 2px solid #efefef;
	background-color:#f0f5fb;
}

.mb-editing-schedule-name-container {
	position: relative;
	display: inline-block;
	margin:0px;
	min-width: 490px;
}

#mb-schedule-name-input {
	width: 100%;
    height: inherit;
    padding: 0px 90px 0px 10px;
    border: 2px solid #2980b9;
    border-radius: 8px;
    background-color: #f0f5fb;
    font-size: 2em;
    font-family: "Nunito", sans-serif;
    font-weight: bold;
}

.mb-editing-schedule-name-confirm {
	position: absolute;
    top: 14px;
    right: 55px;
    font-size: 20px;
}

.mb-editing-schedule-name-cancel {
	position: absolute;
	top: 14px;
    right: 20px;
    font-size: 20px;
}

.mb-other-schedules-icon {
	position: absolute;
	top:32px;
	right:-25px;
	cursor: pointer;
}

.mb-other-schedules-icon:hover {
	font-size: #34495e;
}

.mb-other-schedules-container {
	width: 120%;
	top:80px;
	right:-30px;
	padding:0px 0px 5px 0px;
}

.mb-other-schedules-container > h5 {
	margin: 0px 0px 3px 0px;
    padding: 10px 20px 0px 20px;
}

.mb-other-schedules-option {
	display: grid;
	grid-template-columns: auto 1fr 100px 100px auto;
	column-gap: 20px;
	align-items: center;
	padding:10px 20px 10px 20px;
	margin:auto;
	color:#2c3e50;
	font-size: 14px;
	cursor: pointer;	
}

.mb-other-schedules-option:not(.mb-other-schedules-option-selected):hover {
	background-color: #f9f9f9;
  	color: #000;
}

.mb-other-schedules-option-selected {
	background-color: #2c3e50;
	color:#fff !important;
}

.mb-other-schedules-logo {
	height:20px;
	margin: 6px 0px 0px 0px;
}

.mb-schedule-stats-container {
	justify-self: right;
    display: grid;
    grid-template-columns: 1fr 1fr;
    align-items: center;
}

.mb-match-percent-container {
	display: grid;
    font-size: 12px;
    text-align: right;
    border-radius: 8px;
	padding-right:30px;
}

.mb-match-img {
	height: 40px;
    margin: 16px 0px 0px 0px;
}

.mb-match-percent {
	margin:0px;
	font-size: 3em;
}

.mb-section-conflict {
	background-color: #f8e3e2;
	border:1px solid #e74c3c;
}

.mb-conflict-container {
	display: grid;
    grid-row-gap: 5px;
    grid-row-gap: 5px;
    row-gap: 5px;
    font-size: 12px;
    padding: 0px 0px 0px 20px;
    border-left: 1px dashed #000;
    cursor: pointer;
}

.mb-conflict-inner-row {
	display: grid;
	grid-template-columns: 1fr auto;
	column-gap: 15px;
	align-items: center;
}

.mb-conflict-counter {
	min-width: 20px;
    padding: 1px 8px 1px 8px;
    border-radius: 5px;
    text-align: center;
    background-color: #e8f0fe;
    color: #1a73e8;
}

.mb-menu-bar {
	display: grid;
	grid-template-columns: repeat(auto-fill, minmax(80px, 1fr));
}

.mb-menu-btn {
	position: relative;
	padding:3px 5px;
	text-align: center;
	font-size: 14px;
	border-radius: 5px;
	cursor: pointer;
}

.mb-menu-btn:hover {
	background-color: #efefef;
}

.mb-menu-dropdown-options-container {
	position: absolute;
	width: 100%;
	min-width: 210px;
	max-height: 250px;
	top: 42px;
	left: 0px;
	background-color: #fff;
	border: 1px solid #efefef;
	border-top: none;
	margin-top: -8px;
	box-shadow: 0 0 5px rgba(110, 103, 86, 0.15);
	overflow-y: auto;
	z-index: 99999;
}

.mb-dropdown-options-subcontainer {
	position: absolute;
	width: 100%;
	min-width: 210px;
	max-height: 250px;
	left: 210px;
	background-color: #fff;
	border: 1px solid #efefef;
	border-top: none;
	margin-top: -8px;
	box-shadow: 0 0 5px rgba(110, 103, 86, 0.15);
	overflow-y: auto;
	z-index: 999999;
}

.mb-dropdown-option-parent {
	position: relative;
}

.mb-dropdown-option {
	width: 100%;
	display: grid;
	grid-template-columns: 1fr auto;
	column-gap: 30px;
	align-items: center;
	text-align: left;
	padding: 10px 15px 10px 15px;
	color: #2c3e50;
	font-size: 14px;
	cursor: pointer;
}

.mb-dropdown-option:hover {
	background-color: #efefef;
}

.mb-options-bar {
	display: grid;
    grid-template-columns: 1fr 1fr;
    align-items: center;
}

.mb-search-bar-container {
	position: relative;
    display: grid;
    width: 500px;
    grid-template-columns: 1fr 40px;
}

.mb-search-bar {
	height: 45px;
    border-color: #e5e5e5;
    border-radius: 5px 0px 0px 5px;
    padding: 0px 0px 0px 43px;
    font-size: 14px;
}

.mb-search-bar:focus {
	border-color: #ddd !important;
}

.mb-search-bar-icon {
	position: absolute;
    top: 15px;
    left: 15px;
    color: #dfdfdf;
    transition: 0.3s;
}

.mb-search-bar-container .mb-search-bar:focus + .mb-search-bar-icon {
	color:#2980b9;
}

.mb-search-bar-filter-container {
	display: flex;
	justify-content: center;
	align-items: center;
	border-radius: 0px 5px 5px 0px;
	background-color: #efefef;
	border:2px solid #e9e9e9;
	border-left:none;
	color:#a9a9a9;
	cursor: pointer;
}

.mb-change-view-container {
	justify-self:right;
}

.mb-dropdown-wrapper {
	display: inline-block;
	margin:0px 0px 0px 15px;
}

.mb-change-view-container > .dropdown-container {
	height:44px;
	border:none;
}

.mb-change-view-container > .dropdown-container > .dropdown-options-container {
	top:52px;
}

.mb-selected-filters-text {
	display: inline-block;
	vertical-align: middle;
}

.mb-selected-filters-filter-tag {
	display: inline-block;
    vertical-align: middle;
    font-size: 14px;
    padding: 4px 8px 4px 10px;
    background-color: #f8f8f8;
    border: 1px solid #dddddd;
    border-radius: 5px;
    margin: 0px 0px 0px 10px;
}

.mb-selected-filters-filter-tag-inner-container {
	display: grid;
	grid-template-columns: 1fr auto;
	column-gap: 15px;
	align-items: center;
}

.mb-bottom-container {
	display: grid;
	grid-template-rows: 1fr;
	row-gap: 0px;
	background-color:#fff;
	padding:10px 0px 10px 0px;
}

.mb-message-container {
	width: 100%;
	text-align: center;
	padding:10px;
}

.mb-row {
	display: grid;
	justify-items: center;
	padding: 5px 10px 5px 10px;
	align-items: center;
}

.mb-middle-header {
	background-color:#2c3e50;
	color:#fff;
	padding:10px !important;
}

.mb-middle-header > .mb-inner-box {
	justify-content: center;
}

.mb-main-inner-box {
	width: 100%;
    justify-self: left;
    align-self: center;
    padding-left: 10px;
    font-size: 18px;
}

.mb-inner-box {
	display: grid;
	width: 100%;
    font-size: 12px;
    align-self: center;
}

.mb-department-header {
	width: 100%;
    padding: 0px 0px 0px 20px;
    font-size: 20px;
    font-weight: bolder;
}

.mb-schedule-main-inner-box {
	width: 100%;
    justify-self: left;
    align-self: center;
    padding-left: 10px;
    font-size: 12px;
}

.mb-schedule-inner-box {
	display: flex;
	flex-direction: column;
	position: relative;
	min-width: 0;
	height:100%;
    min-height: 100px;
    border: 1px solid #ddd;
    border-right: none;
    padding: 6px 6px 20px 6px;
    background-color: #f8f8f8;
}

.mb-schedule-row > .mb-schedule-inner-box {
	border-radius: 7px 0px 0px 7px;
}

.mb-schedule-row > .mb-schedule-inner-box  ~ .mb-schedule-inner-box {
    border-radius: 0px;
}

.mb-schedule-row .mb-schedule-inner-box:last-child {
	border-right:1px solid #ddd;
	border-radius: 0px 7px 7px 0px !important;
}

.mb-schedule-inner-box-hovered {
	border:2px solid rgb(210, 210, 210);
}

.mb-schedule-row-name-container {
	padding:5px 5px 5px 5px;
	text-align: center;
}

.mb-schedule-row-selected {
	border:3px solid #3498db;
	border-radius: 8px;
}

.mb-student-percent-indicator {
	display: inline-block;
	height:10px;
	width: 10px;
	border-radius: 10px;
	margin:3px 0px 0px 0px;
}

.mb-section, .mb-student-section, .mb-manual-section {
	position: relative;
	width: 100%;
    height: min-content;
	border-radius: 6px;
	margin:0px 0px 8px 0px;
    box-shadow: 0 0 5px rgba(110, 103, 86, 0.15);
    background-color: #fff;
	text-align: left;
    cursor: move;
    cursor: grab;
}

.mb-section.mb-section-locked {
	cursor: auto;
}

.mb-section-content:hover {
    box-shadow: 0 0 5px rgba(110, 103, 86, 0.25);
}

.mb-section-manual {
	border: 2px solid #cad1d2;
    border-top: none;
}

.mb-student-section {
	cursor:default;
}

.mb-section-top-bar {
	display: grid;
	grid-template-columns: 1fr auto;
	column-gap: 5px;
	padding:1px 5px 0px 5px;
	border-radius: 6px 6px 0px 0px;
	font-size: 10px;
	align-items: center;
}

.mb-section-more-info {
	cursor: pointer;
}

.mb-section-more-info:hover {
	opacity: .9;
}

.mb-section-content, .mb-manual-section-content {
	padding:3px 5px 3px 5px;
	/*border:1px solid #efefef;*/
	border-radius: 0px 0px 6px 6px;
	border-top:none;
}

.mb-manual-section-content {
	padding:5px;
}

.mb-section-subname-container {
	display: grid;
    grid-template-columns: repeat(auto-fit, minmax(25px, auto));
    grid-column-gap: 5px;
    column-gap: 5px;
    color: #a9a9a9;
    margin: 7px 0px 0px 0px;
}

.mb-section-name {
	font-size: 12px;
}

.mb-section-subname, .mb-section-students {
	display: grid;
	grid-template-columns: auto 1fr;
	column-gap: 3px;
	align-items: center;
}

.mb-section-students {
	justify-self: right;
}

.mb-section-quarter-day-container {
	display: grid;
    grid-template-columns: repeat(4, 1fr);
    align-items: center;
    justify-items: center;
    font-size: 8px;
    border-radius: 5px;
    margin: 6px 0px 5px 0px;
}

.mb-section-quarter-day {
	width: 100%;
    /* padding: 1px 0px 1px 0px; */
    text-align: center;
    background-color: #f0f5fb;
    color: #bbb;
	cursor: pointer;
}

.mb-section-quarter-day:not(:last-child) {
	border-right: 1px solid #efefef;
}

.mb-section-quarter-day:last-child {
	border-radius: 0px 3px 3px 0px;
}

.mb-section-quarter-day:first-child {
	border-radius: 3px 0px 0px 3px;
}

.mb-section-quarter-day-selected {
	background-color: #6a92c4 !important;
	color:#fff !important;
	border-right: 1px solid #7da1cc !important;
}

.mb-section-quarter-day:not(.mb-section-quarter-day-selected):hover {
	background-color: #97b7dd;
	color:#fff !important;
}

.mb-lab-days {
	margin:3px 0px 0px 0px;
}

.mb-section-number {
    background-color: #efefef;
    margin: 5px 0px 2px 0px;
    border-radius: 10px;
    text-align: center;
	font-size: 10px;
}

.mb-quick-view-container {
	display: grid;
    grid-template-columns: 330px 1fr;
    background-color: #fff;
    /* padding: 20px 50px 45px 50px; */
    border: 2px solid #ddd;
    border-radius: 10px;
    box-shadow: 0 0 5px rgba(110, 103, 86, 0.25);
    z-index: 999;
}

.mb-quick-view-section-data {
	padding: 30px 40px 40px 40px;
    background-color: #efefef;
}

.mb-quick-view-course-name {
	margin:10px 0px 0px 0px;
}

.mb-quick-view-teacher-name {
	margin: 0px 0px 18px 0px;
}

.mb-quick-view-manual-label {
	width: fit-content;
	padding:3px 10px;
	border-radius: 5px;
	margin-bottom: 20px;
	font-size: 12px;
}

.mb-section-quick-view-quarter-day-container {
	display: grid;
    grid-template-columns: repeat(4, 1fr);
    align-items: center;
    justify-items: center;
    border-radius: 5px;
    margin: 30px 0px 20px 0px;
	min-height: 18px;
}

.mb-section-quick-view-quarter-day {
	display: grid;
	align-content: center;
	width: 100%;
	height: 100%;
    text-align: center;
    background-color: #f0f5fb;
    color: #bbb;
}

.mb-section-quick-view-quarter-day:not(:last-child) {
	border-right: 1px solid #efefef;
}

.mb-section-quick-view-quarter-day:last-child {
	border-radius: 0px 3px 3px 0px;
}

.mb-section-quick-view-quarter-day:first-child {
	border-radius: 3px 0px 0px 3px;
}

.mb-quick-view-conflicts-container {
	/* margin:0px 0px 0px 40px; */
	padding:0px 0px 40px 50px;
	border-left:1px solid #ddd;
}

.mb-add-new-section {
	position: absolute;
    bottom: 0px;
    left: 0px;
    width: 100%;
    text-align: right;
    font-size: 14px;
    padding: 0px 3px 0px 0px;
}

.mb-section-label {
	display: grid;
    /* width: fit-content; */
    grid-template-columns: auto 1fr auto;
    grid-column-gap: 8px;
    align-items: center;
    margin: 2px 0px 2px 0px;
    padding: 0px 5px 0px 5px;
    border: 1px solid #efefef;
    border-radius: 30px;
    font-size: 12px;
}

.mb-section-label-icon {
	width:8px;
	height:8px;
	border-radius: 8px;
}

.swal-custom-save-schedule-name {
    width:fit-content;
}

.mb-saving-container {
	margin:30px auto 70px auto;
	padding:0px 50px 0px 30px;
	text-align: left;
}

.mb-saving-heading {
	margin:0px 0px 0px 0px;
}

.mb-saving-inner-container {
	display: grid;
	row-gap: 20px;
}

.mb-saving-row {
	display: grid;
	grid-template-columns: 250px 1fr;
	column-gap: 30px;
}

.mb-saving-progress {
	min-width: 150px;
}

#mb-saving-section-progress {
	animation: mb-saving-progress 4s;
	animation-fill-mode: forwards;
}

#mb-saving-teacher-progress {
	animation: mb-saving-progress 3s;
	animation-fill-mode: forwards;
	animation-delay: 4s;
}

#mb-saving-classroom-progress {
	animation: mb-saving-progress 3s;
	animation-fill-mode: forwards;
	animation-delay: 7s;
}

#mb-saving-student-progress {
	animation: mb-saving-progress 10s;
	animation-fill-mode: forwards;
	animation-delay: 10s;
}

#mb-saving-copying-progress {
	animation: mb-saving-progress 5s;
	animation-fill-mode: forwards;
	animation-delay: 20s;
}

@keyframes mb-saving-progress {
	0%   {width: 0%;}
	99%	 {background-color: #3498db;}
	100% {width: 100%;background-color: #2ecc71;}
}

.placing-students-in-progress-icon {
	-webkit-animation: placing-students-in-progress 10s infinite;
    -moz-animation: placing-students-in-progress 10s infinite;
    -o-animation: placing-students-in-progress 10s infinite;
    -ms-animation: placing-students-in-progress 10s infinite;
	animation: placing-students-in-progress 10s infinite;
}

@keyframes placing-students-in-progress {
	0% {color: #2980b9;}
	10% {color: #e67e22;}
	20% {color: #f1c40f;}
	30% {color: #27ae60;}
	40% {color: #16a085;}
	50% {color: #3498db;}
	60% {color: #8e44ad;}
	70% {color: #74b9ff;}
	80% {color: #00b894;}
	90% {color: #fdcb6e;}
	100% {color: #2980b9;}
}

.print-period-name {
	height: 0px;
	overflow:hidden;
}

.export-schedule-name-container {
	display:grid;
	grid-template-columns: auto 1fr;
	column-gap: 20px;
	align-items: center;
}

.export-schedule-name-container svg {
	font-size: 25px;
}

.export-schedule-name {
	height:40px;
	line-height: 40px;
	font-size: 16px;
}

.export-schedule-label {
	font-size: 12px;
	color:#a9a9a9;
	text-align: left;
	margin:20px 0px 10px 0px;
}

.export-schedule-option-container {
	display:grid;
	grid-template-columns: 1fr auto;
	column-gap: 100px;
	text-align: left;
}

.mb-create-health-map-parent-container {
	margin: 30px 0px 10px 0px;
    padding: 30px 0px 50px 0px;
    border-top: 2px solid #efefef;
    border-bottom: 2px solid #efefef;
}

.mb-create-health-map-container {
	display:grid;
	grid-template-columns: 1fr auto 1fr auto;
	column-gap: 20px;
	margin:30px 0px 0px 0px;
	align-items: center;
	text-align: center;
}

.mb-create-health-map-header {
	font-size: 17px;
    text-decoration: underline;
    margin: 0px;
}

.mb-create-health-map-btn {
	width: 100%;
	max-width: 220px;
	padding:5px 0px 5px;
	text-align: center;
	font-size: 14px;
	margin:20px auto 10px auto;
}

.mb-create-health-map-link {
	width: 100%;
	text-align: center;
	font-size: 14px;
}

.mb-create-health-map-search-container {
	position: relative;
	padding:10px;
	background-color: #efefef;
}

.mb-create-health-map-search-add-did-you-mean {
	font-size: 14px;
    margin: 5px 0px 5px 0px;
    font-weight: bold;
    color: #e67e22;
}

.mb-create-health-map-search-add-search-container {
	width: 100%;
    padding: 0px 0px 0px 0px;
}

.mb-create-health-map-search-add-cancel-new-btn {
	position: absolute;
	top:8px;
	right:8px;
}

.mb-create-health-map-search-add-search-results {
	margin: 15px 0px 15px 0px;
    max-height: 300px;
    overflow-y: auto;
    font-size: 14px;
    padding: 0px 10px 0px 0px;
}

.mb-create-health-map-pair {
	padding:10px 5px;
}

.mb-unassigned-sections-parent-container {
	display: grid;
	grid-template-columns: 160px 1fr;
	align-items: center;
	padding:0px 0px 15px 0px;
}

.mb-unassigned-sections-container {
	margin:0px 10px;
}

.mb-unassigned-sections-dropzone {
	display: grid;
    grid-template-columns: repeat( auto-fit, minmax(100px, 140px));
    grid-column-gap: 20px;
    column-gap: 20px;
    /* background-color: #f8f8f8; */
    border-radius: 8px;
    padding: 10px;
    min-height: 115px;
    border: 2px dashed #ddd;
}

.mb-unassigned-sections-hover-over {
	border:3px solid #ddd !important;
}

.mb-highlight-section {
	border: 2px solid #ffff00 !important;
}


/***********************************************************************/
/***********************************************************************/
/******************** ELEMENTARY SCHOOL MAGNETBOARD ********************/
/***********************************************************************/
/***********************************************************************/
.mb-elem-container {
	width: 100%;
	max-width: 1800px;
	padding:30px 50px 250px 50px;
}

.mb-elem-teacher-schedule {
	margin: 15px 0px 0px 0px;
	min-width: 1050px;
    padding: 12px 30px 18px 30px;
    border-left: 5px solid #34495e;
    background-color: #fff;
	border-radius:0px 10px 10px 0px;
	box-shadow: 0 0 5px rgba(110, 103, 86, 0.15);
}

.mb-elem-teacher-row {
	display: grid;
	grid-template-columns: auto 1fr;
	align-items: center;
	column-gap: 40px;
	cursor: pointer;
}

.mb-elem-teacher-info {
	position: relative;
}

.mb-elem-edit-teacher-info {
	position: absolute;
    top: 5px;
    right: -28px;
}

/* .mb-elem-teacher-row:hover {
	box-shadow: 0 0 5px rgba(110, 103, 86, 0.15);
} */

.mb-elem-schedule-name-container {
	display: grid;
	grid-template-columns: 1fr 300px;
	column-gap: 50px;
}

.mb-elem-top-bar {
	width: 100%;
    padding: 0px 0px 18px 0px;
    border-radius: 20px 20px 0px 0px;
    border-bottom: none;
    /* background-color: #fff; */
}

.mb-elem-top-bar h1 {
	font-size: 50px;
	margin:0px 0px 10px 0px;
}

.mb-elem-teacher-name {
	margin: 0px;
	font-weight: 600;
}

.mb-elem-homeroom-info {
	display: grid;
    grid-template-rows: 1fr 1fr;
    grid-row-gap: 10px;
    row-gap: 10px;
    font-size: 16px;
    margin: 5px 0px 0px 2px;
}

.mb-elem-homeroom-info-line {
	display: grid;
	grid-template-columns: 20px 1fr;
	column-gap: 15px;
	align-items: center;
}

.mb-elem-week-schedule-container {
	display: grid;
    grid-template-columns: auto 1fr;
    /* grid-column-gap: 50px; */
    column-gap: 20px;
    align-items: flex-start;
	margin: 0px 0px 100px 0px;
}

.mb-elem-week-schedule-time-container {
	display: grid;
    align-items: start;
    font-size: 14px;
    padding: 10px 0px;
	margin:65px 0px 0px 0px;
}

.mb-elem-week-schedule-days-container {
	display: grid;
	min-width: 900px;
	grid-template-columns: repeat(auto-fit, minmax(100px, 1fr));
	column-gap: 30px;
	align-items: flex-start;
}

.mb-elem-time-delimiter {
	display: grid;
	grid-template-columns: 65px 1fr;
	column-gap: 10px;
	height:72px;
	border-top: 2px dotted #ddd;
}

.mb-elem-time-dotted-line {
	border-top: 2px dotted #ddd;
	margin:10px 0px 0px 0px;
}

.mb-elem-week-day-container {
	position: relative;
	text-align: center;
}

.mb-elem-week-day-header {
	font-weight: 500;
}

.mb-elem-week-day-block-parent-container {
	position:relative;
	height:648;
	background-color: #efefef;
	border-radius: 5px;
	padding:10px;
}

.mb-elem-week-day-grid {
	display: grid;
	width: 100%;
	height:700px;
	grid-template-columns: repeat(60, 1fr);
	grid-template-rows: repeat(576, 1fr);
}

.mb-elem-add-block-container {
	position: absolute;
    bottom: 12px;
    left: 15px;
    display: grid;
    grid-template-columns: auto 1fr;
    grid-column-gap: 10px;
    column-gap: 10px;
    align-items: center;
    font-size: 20px;
	text-align: left;
}

.mb-elem-block {
	display: grid;
	cursor: pointer;
}

.mb-elem-block:hover {
	z-index: 9;
}

.mb-elem-block-inner {
	position: relative;
	display: grid;
    grid-template-rows: auto 1fr;
    font-size: 12px;
    text-align: left;
    padding: 2px 0px 2px 4px;
    background-color: #fff;
    border-radius: 0px 5px 5px 0px;
	border:1px solid #eee;
}

.mb-elem-block-selected {
	border-top-width: 2px !important;
	border-bottom-width: 2px !important;
	border-right-width: 2px !important;
	border-radius: 0px 8px 8px 0px;
	z-index: 999;
  }

.mb-elem-block-header {
	position: relative;
    display: grid;
    grid-template-columns: 1fr auto;
    grid-column-gap: 10px;
    column-gap: 10px;
    align-items: center;
    border-radius: 5px 5px 0px 0px;
    min-width: 0;
	padding:0px 5px 0px 0px;
}

.mb-elem-block-name {
	font-weight: bold;
	font-size: 13px;
}

.mb-elem-block-content {
	position: relative;
    border-radius: 0px 0px 5px 5px;
    background-color: #fff;
    min-width: 0;
}

.mb-elem-block-time {
	min-width: 0;
}

.elem-lock-block-btn {
	position:absolute;
	bottom:2px;
	right:5px;
}

.mb-elem-conflicts-link {
	margin:10px 0px 0px 0px;
}

.mb-elem-conflict {
	display:grid;
	grid-template-columns: auto 1fr;
	column-gap: 10px;
	align-items: center;
	width: 75%;
	padding:10px;
	border:1px solid #efefef;
	border-radius: 5px;
	margin:10px 0px 0px 0px;
	background-color:#fafafa;
}

.mb-circular-chart {
	display: block;
	width:40px;
}

.mb-circle-bg {
	fill: none;
	stroke: #eee;
	stroke-width: 3.8;
}

.mb-circle {
	fill: none;
	stroke-width: 2.8;
	stroke-linecap: round;
}

.mb-circular-chart.red-stroke .mb-circle {
	stroke: #c0392b;
}

.mb-circular-chart.yellow-stroke .mb-circle {
	stroke: #f39c12;
}

.mb-circular-chart.green-stroke .mb-circle {
	stroke: #27ae60;
}

.mb-percentage {
	fill: #666;
	font-family: sans-serif;
	font-size: 10px;
	text-anchor: middle;
}