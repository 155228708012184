#assign-resources-department-list {
	width: 100%;
}

.department-row, .assign-resources-add-row-container {
	position: relative;
	padding:15px 15px 15px 20px;
	background-color: #fff;
	margin:5px 0px 15px 0px;
	border-radius: 8px;
	box-shadow: 0 0 5px rgba(110, 103, 86, 0.15);
}

.department-inner-row {
	width:100%;
	display:grid;
	grid-template-columns: 1fr 1fr 40px;
	column-gap: 20px;
	align-items: center;
}

.assign-resources-container {
	width:400px;
	display:inline-block;
	margin:0px auto 0px auto;
}

.assign-resources-remove-department {
	position: absolute;
	top:6px;
	right:6px;
	font-size: 20px;
	color:#a9a9a9;
	cursor: pointer;
}

.assign-resources-remove-department:hover {
	color:#a9a9a9;
}

.assign-resources-label {
	font-size: 12px;
	color:#a9a9a9;
	justify-self:left;
	margin:0px 0px 10px 0px;
}

.assign-resources-department {
	text-align: left;
	margin:0px 0px 15px 0px;
	font-size: 24px;
}

.edit-department {
	font-size: 14px;
	margin:0px 0px 0px 10px;
	padding:0px 0px 2px 0px;
	color:#a9a9a9;
	cursor: pointer;
}

.edit-department:hover {
	color:#a9a9a9;
}

.edit-department-row-container {
	width: 100%;
	display: grid;
	grid-template-columns: 1fr auto auto;
	column-gap: 30px;
	align-items: center;
}

.edit-department-input {
	width: 100%;
	height:40px;
	line-height: 40px;
	border:2px solid #efefef;
	border-radius:5px;
	padding:0px 0px 0px 10px;
	font-size: 15px;
}

.assign-supervisor-supervisors-container {
	display: grid;
    row-gap: 0px;
    margin: 18px 0px 30px 0px;
}

.assign-supervisor-supervisors-inner-container {
	display: grid;
	grid-template-columns: 42px 1fr 15px;
	justify-items:left;
	align-items: center;
	font-size: 14px;
}

.assign-supervisor-add-container {
	position: relative;
	display: grid;
	grid-template-columns: 1fr;
	justify-items:left;
	align-items: center;
	margin:25px 0px 5px 0px;
	color:#2980b9;
	cursor: pointer;
}

.assign-supervisor-add-container:hover {
	color:#3498db;
}

.assign-supervisor-icon {
	font-size: 15px;
}

.assign-supervisor-add-text {
	font-size: 14px;
}

.assign-resourses-supervisor-option {
	display: grid;
	grid-template-columns: 40px 1fr;
	align-items: center;
}

.add-assign-resources-row {
	margin:30px 0px 30px 0px;
	color:#2980b9;
	cursor: pointer;
}

.add-assign-resources-row:hover {
	color:#3498db;
}

#assign-resources-dashboard {
	width: 100%;
    max-width: 1200px;
    min-width: 860px;
    margin: auto;
}

.unassigned-resource-counter {
	position: absolute;
	display: flex;
	top:20px;
	right:13px;
	width:22px;
	height:22px;
	justify-content: center;
	align-items: center;
	border-radius: 4px;
	font-size: 12px;
	background-color:#2980b9;
	color:#fff;
}

.screen-sidebar-btn-selected > .unassigned-resource-counter {
	background-color:#fff;
	color:#2980b9;
}

#assign-resources-content {
	position: relative;
	width: 100%;
	max-width: 930px;
	padding:20px 30px 20px 40px;
	margin: 0px auto 0px auto;
	text-align: left;
	background-color:#fff;
	border-radius: 10px;
	box-shadow: 0 0 5px rgba(110, 103, 86, 0.15);
}

.assign-resources-content-header-container {
	display: inline-grid;
	grid-template-columns: auto auto;
	column-gap: 20px;
	margin:0px 0px 5px 0px;
	align-items: center;
}

.assign-resources-content-header {
	margin:0px 0px 5px 0px;
	color: #2c3e50;
}

.assign-resources-status {
	display: grid;
    grid-template-columns: auto 20px;
    column-gap: 7px;
    align-items: center;
}

#assign-resources-submit-btn-container {
	display: grid;
	grid-template-columns: 1fr;
	position: absolute;
    top: 20px;
    right: 25px;
	justify-items: end;
}

#assign-resources-submit-btn-container .gray-btn {
	max-width: 127px;
	margin:0px;
}

.assign-resources-supervisor-inner-container {
	float:left;
	display: grid;
	grid-template-columns: 35px 1fr;
	margin:6px 10px 0px 0px;
	align-items: center;
	border:1px solid #ddd;
	border-radius: 20px;
	padding:6px 10px 6px 10px;
}

.assign-resources-department-supervisor-heading {
	margin:0px 0px 5px 0px;
}

.assign-resources-screen-message {
	width: 100%;
    display: grid;
    align-items: center;
    justify-items: center;
    text-align: center;
    padding: 60px 50px 70px 50px;
    margin: 30px auto 0px auto;
    border:2px dashed #efefef;
    border-radius: 10px;
	background-color: #fff;
    color: #2c3e50;
}

#assign-resources-unassigned-content {
	height: 820px;
	overflow-y: auto;
	padding:0px 20px 0px 0px;
}

.assign-resources-unassigned-header {
	display: grid;
    grid-template-columns: 2fr 2fr 20px;
    align-items: center;
    font-size: 12px;
    margin: 0px 0px 10px 0px;
    background-color: #fff;
    font-weight: bold;
}

.assign-resources-unassigned-header-department {
	justify-self: center;
    text-align: center;
}

.assign-resources-row {
	display: grid;
    grid-template-columns: 1fr 1fr auto;
    column-gap: 30px;
    align-items: center;
    padding: 10px 10px 10px 10px;
    font-size: 14px;
    border: 1px solid #efefef;
    border-radius: 5px;
    margin: 0px 0px 5px 0px;
}

.assign-resources-unassigned-department-container {
	justify-self: center;
    text-align: center;
}

.assign-resources-unassigned-department, assign-resources-unassigned-choose-department {
	display: inline-block;
}

.assign-resources-unassigned-department {
	margin:0px 15px 0px 0px;
}

.assign-resources-supervisor-confirm-container {
	position: relative;
	max-height: 800px;
	margin:30px 0px 30px 0px;
	padding: 0px 15px 0px 2px;
	overflow-y: auto;
	scroll-behavior: smooth;
}

#assign-resources-teachers-classrooms-column {
	display: grid;
	grid-template-columns: 1fr;
	grid-gap: 40px;
	grid-auto-rows: min-content;
}

.assign-resources-supervisor-confirm-header {
	display: grid;
	grid-template-columns: 1fr 20px;
	align-items: center;
	padding: 5px 10px;
    border-radius: 8px;
    margin:0px 0px 10px 0px;
    font-size: 20px;
}

.assign-resources-supervisor-confirm-data-container {
	margin:0px 0px 30px 0px;
}

.assign-resources-no-data-row {
	width:100%;
	padding:10px;
	border:2px dashed #efefef;
	color:#bcbcbc;
}

.assign-resources-supervisor-confirm-add-new {
	width: 100%;
    text-align: right;
    padding: 8px 5px 8px 0px;
    font-size: 14px;
}

.assign-resources-fixed-bottom-save-container {
    position: absolute;
    width: 100%;
    bottom: 0px;
    left: 0px;
    padding: 15px;
    text-align: right;
    background-color: #34495e;
    border-radius: 0px 0px 10px 10px
}

.dual-dept-label {
	font-size: 12px;
	color:#f39c12;
	margin:0px 0px 0px 15px;
}

.next_assign_step {
	display: inline-block;
	width:280px;
	background-color:#27ae60;
	color:#fff;
	text-align:center;
	padding:15px;
	font-size: 20px;
	border-radius: 5px;
	cursor: pointer;
}

.next_assign_step:hover {
	background-color: #2ecc71;
}

.assign-resources-cancel-new-btn, .assign-resources-add-new-btn {
	margin:15px 0px 0px 0px;
}

.assign-resources-adding-label {
	text-align: left;
	font-size: 12px;
	color:#a9a9a9;
}

.assign-resources-add-new-data-container {
	position: relative;
    padding: 15px 20px 15px 20px;
    background-color: #fff;
    margin: 10px 0px 15px 0px;
    border-radius: 8px;
    box-shadow: 0 0 5px rgba(110, 103, 86, 0.15);
}

.assign-resources-add-new-data-container-header {
	display:grid;
	grid-template-columns:1fr auto;
	column-gap:30px;
	align-items: center;
	margin:0px 0px 10px 0px;
}

.assign-resources-add-new-data-container-header > h4 {
	margin:0px;
	color:#2c3e50;
}

.assign-resources-add-new-data-inner-container {
	display: grid;
	column-gap: 35px;
	align-items: center;
}

.assign-resources-add-new-data-btn-container {
	display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 20px;
    align-items: center;
    font-size: 22px;
    margin: 16px 0px 0px 0px;
}

.assign-resources-add-new-data-text {
	padding: 20px 0px 0px 10px;
    margin: 0px 0px 10px 0px;
}










