#switch-years-modal-content {
	width:500px;
	text-align: left;
	box-sizing: border-box;
	padding-bottom: 40px;
  margin-top: 5%;
}

#switch-years-modal-title {
	margin:0px 0px 8px 0px;
}

.switch-years-subtitle {
	font-size: 18px;
	margin:30px 0px 20px 0px;
}

.switch-years-schedule-select-container {
	max-width: 350px;
    display: grid;
    grid-template-columns: 30px 1fr 20px;
    grid-column-gap: 15px;
    column-gap: 15px;
    align-items: center;
    margin: 0px 0px 10px 0px;
    background-color: #efefef;
    padding: 8px 8px 8px 15px;
    border-radius: 5px;
    cursor: pointer;
}

.switch-years-schedule-select-container-disabled {
  opacity: .6;
  cursor: not-allowed !important;
}

.switch-years-schedule-select-box {
	font-size: 20px;
}

.switch-years-schedule-select-box.fas-checkbox-unchecked {
	color:#cccccc !important;
}

.switch-years-constraint-explanation {
	max-width: 290px;
	margin:-5px 0px 14px 60px;
	font-size: 12px;
}

.switch-years-btn-container {
	display: grid;
    grid-column-gap: 15px;
    column-gap: 15px;
    align-items: center;
    margin-top: 60px;
}

.switch-years-radio-btn {
	height:25px;
}