#dashboard-parent-container {
	min-width: 600px;
}

#dashboard-scheduling-progress {
	grid-area: a;
}

#dashboard-teacher-utilization {
	grid-area: b;
}

#dashboard-classroom-utilization {
	grid-area: c;
}

#dashboard-students-scheduled {
	grid-area: d;
}

#dashboard {
	display: grid;
	grid-template-areas: "a a a a" "d d b b" "d d c c";
	column-gap: 20px;
	row-gap: 20px;
	max-width: 800px;
	margin:auto;
}

.dashboard-container {
	/*display: grid;
	grid-template-columns: 1fr auto;
	column-gap: 30px;
	align-items: center;*/
	background-color: #fff;
	border-radius: 5px;
	padding:10px 20px 30px 20px;
}

.dashboard-container h4 {
	margin:0px;
	text-align: left;
}

.dashboard-percentage-container {
	max-width: 270px;
	margin:40px auto;
}

.dashboard-schedule-progress-inner-container {
	display: grid;
	grid-template-columns: 1fr 1fr;
	column-gap: 50px;
}

#dashboard-department-progress-container {
	padding:0px 30px 0px 0px;
}

.dashboard-department-progress {
	display: grid;
    grid-template-columns: 1fr 30px;
    grid-column-gap: 30px;
    column-gap: 30px;
    align-items: center;
    text-align: left;
    padding: 5px 0px;
}

.dashboard-utilization-percentage-container {
	max-width: 140px;
	margin: 20px auto 0px auto;
}

.dashboard-grade-stats {
	display: grid;
    grid-template-columns: 1fr 30px;
    grid-column-gap: 30px;
    column-gap: 30px;
    align-items: center;
    text-align: left;
    padding: 5px 60px;
}

.dashboard-details-link {
	margin:20px 0px 0px 0px;
}

.dashboard-details-container {
	text-align: left;
	margin: 30px 30px 0px 30px;
}

.dashboard-detail-row {
	display: grid;
	grid-template-columns: 1fr 30px;
	column-gap: 30px;
	align-items: center;
	padding:5px 0px;
}