#progress-page-main-container {
	display: grid;
}

#progress-page-sidebar {
    position: fixed;
    height: 100%;
	width: 220px;
	background-color: #fff;
	padding:0px 0px 200px 0px;
	overflow-y: auto;
	z-index: 100;
}

.progress-main-header {
	font-size:45px;
	margin:0px 0px 15px 0px;
}

.progress-subheader {
	font-size:16px;
	margin:0px 0px 50px 0px;
}

.progress-page-sidebar-btn {
	position: relative;
	display: grid;
	grid-template-columns: auto 1fr auto;
	column-gap: 10px;
	align-items: center;
	padding:10px 15px 10px 18px;
}
.progress-page-sidebar-btn:not(.progress-page-sidebar-btn-selected):hover {
	background-color: #f8f8f8;
}

.progress-page-sidebar-btn-selected {
	background-color: #34495e;
	color:#fff;
}

.progress-sidebar-step-number {
	height: 20px;
    line-height: 20px;
    width: 20px;
    border-radius: 30px;
    text-align: center;
    font-size: 12px;
    margin: auto;
}

.progress-sidebar-step-name {
	font-size: 12px;
}

.progress-sidebar-step-check {
	font-size: 16px;
}

.progress-page-sidebar-btn-selected .progress-step-button-container {
	background-color: #fff;
	border-radius: 40px;
}

.progress-sidebar-step-arrow {
	font-size: 14px;
}

.progress-sidebar-step-pending {
	color:#cacaca;
	font-size: 14px;
}

.progress-page-sidebar-subscreens-container {
	background-color: #f7f7f7;
	border-top:1px solid #ddd;
	border-bottom:1px solid #ddd;
	padding:10px 0px 10px 0px;
}

.progress-page-sidebar-subscreen-btn {
	position: relative;
    display: grid;
    grid-template-columns: 8px 1fr auto;
    grid-column-gap: 10px;
    column-gap: 10px;
    align-items: center;
    padding: 0px 15px 0px 15px;
    font-size: 12px;
    cursor: pointer;
}

.progress-page-sidebar-subscreen-progress-line, .progress-page-sidebar-department-progress-line {
	height: 100%;
	background-color: #dddddd;
}

.progress-page-sidebar-subscreen-name {
	padding: 10px 10px 10px 10px;
}

.progress-page-sidebar-departments-container {
	background-color: #f0f0f0;
	border-top: 1px solid #d8d8d8;
	border-bottom: 1px solid #d8d8d8;
	padding: 20px 0px 30px 0px;
	margin: 10px 0px 10px 0px;
	}

.progress-page-sidebar-department-btn {
	position: relative;
    padding: 0px 18px 0px 24px;
    font-size: 12px;
    cursor: pointer;
}

.progress-page-sidebar-department-btn-inner {
    display: grid;
    grid-template-columns: 3px 1fr auto;
    grid-column-gap: 10px;
    column-gap: 10px;
    align-items: center;
	background-color: #fff;
	padding-right:6px;
}

.progress-page-sidebar-department-name {
	padding: 8px 0px 8px 0px;
}

#progress-page-main-content {
	padding:0px 0px 0px 220px;
}

#progress-page-welcome-screen {
	display: grid;
    grid-template-columns: 1fr 3fr;
    grid-column-gap: 50px;
    column-gap: 30px;
    text-align: center;
    opacity: 0.0;
    animation: fadeIn ease 3s forwards;
    -webkit-animation: fadeIn ease 3s forwards;
    padding-bottom: 150px;
    max-width: 1200px;
    margin: 40px;
}

#progress-page-welcome-sidebar {
	background-color: #efefef;
    padding: 40px;
	border-radius: 30px 0px 0px 30px;
}

#progress-page-welcome-screen-image {
	max-width: 300px;
	margin: 20px 0px -40px 0px;
}

#progress-container {
	width: 560px;
	margin:auto;
	margin:50px auto 100px auto;
	animation: fadeIn ease 3s forwards;
	-webkit-animation: fadeIn ease 3s forwards;
}

.progress-step-container
{
	display: grid;
    grid-template-columns: 1fr 100px;
    grid-column-gap: 60px;
    align-items: center;
    align-content: center;
    width: 100%;
    position: relative;
    background-color: #fff;
    padding: 20px 20px 20px 70px;
    margin: 0px 0px 25px 0px;
    border-radius: 8px;
}

.progress-step-number {
	position: absolute;
    top: 0px;
    bottom: 0px;
    left: -30px;
    height: 60px;
    line-height: 60px;
    width: 60px;
    border-radius: 30px;
    text-align: center;
    font-size: 20px;
    margin: auto;
}

.progress-step-name {
	color: #2980b9;
}

.progress-step-explanation {
	margin:5px 0px 0px 0px;
	color:#34495e;
}

.progress-step-button-container {
	display: grid;
    align-self: center;
    justify-items: right;
}

.progress-step-arrow-container {
	font-size: 18px;
}

.progress-step-arrow {
	color: #34495e;
	cursor: pointer;
}

.progress-step-arrow:hover {
	color:#2c3e50;
}

.progress-step-check {
	font-size:34px;
	color:#2ecc71;
}

.progress-step-edit {
	margin:3px 0px 3px 0px;
	color: #a9a9a9;
	cursor: pointer;
	font-size:12px; 
}

.progress-step-edit:hover {
	color:#2c3e50;
}

.progress-step-pending {
	color: #a9a9a9;
	font-size: 12px;
}

.progress-step-awaiting-confirmation {
	position: absolute;
	bottom:7px;
	right:10px;
	color: #f1c40f;
	font-size: 12px;
	width:max-content;
}

.progress-choose-department-screen {
	width: fit-content;
	min-width: 800px;
	max-width: 1200px;
	position: relative;
	background-color:#fff;
	border-radius: 10px;
	box-shadow: 0 0 5px rgba(110, 103, 86, 0.15);
	text-align: left;
	padding:30px 40px 30px 40px;
	margin:-16px 0px 0px 0px;
}

.progress-choose-department-heading {
	font-size: 20px;
	font-weight: bold;
}

.progress-choose-department-container {
	margin:30px 0px 0px 0px;
}

.progress-choose-department-row {
	display: grid;
	grid-template-columns: 1fr 180px 200px;
	column-gap: 50px;
	align-items: center;
	padding:15px 0px;
	border-bottom: 1px solid #ddd;
}

.progress-choose-department-row:last-child {
	border-bottom: none;
}

.progress-choose-department-name {
	font-weight: bold;
	font-size: 16px;
}

.progress-choose-department-status {
	display: grid;
	grid-template-columns: 1fr 20px;
	column-gap: 10px;
	align-items: center;
	text-align: right;
	font-size: 14px;
}

.progress-choose-department-status-icon {
	font-size: 24px;
}

.progress-choose-department-btn {
	max-width: 100px;
}

.progress-back-to-departments-btn {
	margin: 28px 0px 0px 20px;
    font-size: 30px;
	cursor: pointer;
}

.progress-back-to-departments-btn:hover {
	color:#6e869c;
}