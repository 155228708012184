#lunch-structure-options-container {
	width:100%;
	margin:0px 0px 20px 0px;
}

#lunch-structure-num-lunches-text {
	margin:0px 0px 15px 0px;
	padding:0px;
}

#lunch_structure_wrapper {
	display: grid;
	justify-content: center;
}

#lunch_structure_container {
	display: grid;
    width: 100%;
    margin: 40px auto 0px auto;
    column-gap:20px;
    align-items: center;
    justify-items: center;
}

#other-courses-besides-lunch-container {
	position:relative;
	width:80%;
	margin:40px 0px 0px 0px;
}

#other-periods-explanation-icon {
	margin:3px 0px 0px 10px;
	color:#2980b9;
	cursor: pointer;
}

#other-periods-explanation-icon:hover {
	color:#3498db;
}

.daily-lunch-optn-container {
	display: grid;
    width: 100%;
    grid-template-columns: 1fr;
    padding: 20px;
    background-color: #fff;
    border-radius: 8px;
    box-shadow: 0 0 5px rgba(110, 103, 86, 0.15);
}

.daily-lunch-container {
	display:grid;
	grid-gap:1rem;
	margin: 10px 0px 0px 0px;
	align-items: center;
	justify-items: center;
	
}

.daily-lunch-optn-header {
	margin:0px 0px 10px 0px;
	color:#2c3e50;
}

.daily-lunch-lunchbox {
	width:100%;
	height:100%;
	background-color:#16a085;
	text-align:center;
	padding:10px;
	border-radius: 5px;
}

.daily-lunch-coursebox {
	width: 100%;
	height:100%;
	background: repeating-linear-gradient(
	  -55deg,
	  #efefef,
	  #efefef 10px,
	  #fff 10px,
	  #fff 20px
	);
	border-radius: 5px;
}

.number-of-other-courses-text {
	font-size:30px;
	color:#a9a9a9;
}

.daily-lunch-text {
	display:block;
	margin:0px 0px 10px 0px;
}

.daily-slot-text {
	display:block;
	margin:30px 0px 20px 0px;
}

.daily-lunch-select-time-box {
	position: relative;
	height:25px;
	margin:auto;
	font-size:10px;
	border-radius:4px;
}

.lunch-select-time-box {
	width:90%;
	position: relative;
	height:20px;
	margin:auto;
	background-color:#fff;
	font-size:10px;
	border-radius:4px;
	border:1px solid #d0d0d0;
}

.daily-select-time-box {
	width:90%;
	position: relative;
	height:20px;
	margin:auto;
	background-color:#fff;
	font-size:10px;
	border:1px solid #d0d0d0;
	color:#d0d0d0 !important;
	/*border-radius:10px;*/
}

.daily-select-time-box-active {
	color:#000 !important;
}

.lunch-course-draggable-active {
	border:1px solid #2980b9;
}

.continued-div {
	color:#a9a9a9;
	font-size:12px;
}

.remove-lunch-course {
	display: none;
	position: absolute;
	top:0px;
	left:100%;
	margin-left:-16px;
	color: #efefef;
	cursor:pointer;
}

.remove-lunch-course:hover {
	color:#fff;
}

#lunch-structure-error {
	margin:10px auto -50px auto;
}





