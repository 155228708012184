#homepage-content {
	width: 100%;
	height: 100%;
	background-color: #fff;
	overflow-y: auto;
	scroll-behavior: smooth;
}

#homepage-navbar {
	display: grid;
    grid-template-columns: auto 1fr;
    align-items: center;
    grid-column-gap: 40px;
    column-gap: 40px;
    padding: 20px 40px 20px 30px;
}

#homepage-logo-container {
	display: grid;
    grid-template-columns: auto 1fr;
    grid-column-gap: 15px;
    column-gap: 15px;
    align-items: center;
}

#homepage-link-container {
	display: grid;
    grid-template-columns: repeat(auto-fill, minmax(80px, 1fr));
    text-align: center;
	column-gap: 15px;
}

.homepage-link {
	padding:0px 10px;
	cursor: pointer;
}

.homepage-link:hover {
	color:#000;
}

.homepage-banner {
	font-size: 22px;
    padding: 50px 0px 100px 0px;
}

#homepage-banner-main-inner {
	width: 100%;
    height: 100%;
    background: rgb(255,255,255);
	background: linear-gradient(137deg, rgba(255,255,255,1) 53%, rgba(41,128,185,1) 80%, rgba(9,9,121,1) 100%);
    padding: 50px 20px;
    overflow-x: hidden;
	margin:-50px 0px;
}

#homepage-main-banner {
	display: grid;
    align-items: center;
    grid-template-columns: repeat(auto-fit, minmax(240px, 1fr));
    max-width: 1050px;
    margin: auto;
}

#homepage-main-banner-heading {
	font-size: 25px;
    line-height: 30px;
    margin: 20px 0px 20px 0px;
}

#homepage-video-container {
	position: relative;
}

#homepage-video-container img {
	height:700px;
}

#homepage-video-container-background {
	position: absolute;
	height:263px;
	width:494px;
    top: 175px;
    left: 78px;
	background-color: #fff;
}

#homepage-video-container video {
	height: 263px;
    position: absolute;
    top: 175px;
    left: 108px;
}

.homepage-feature-container {
	display: grid;
    width: -moz-fit-content;
    width: fit-content;
    grid-template-columns: repeat(auto-fit, minmax(240px, 450px));
    max-width: 1050px;
    margin: auto;
    align-items: center;
    padding: 60px 0px;
}

.homepage-feature-left {
	position: relative;
	justify-self: left;
}

.homepage-feature-right {
	position: relative;
	justify-self: right;
}

.homepage-random-blob {
	position: absolute;
	top:0px;
	left:0px;
	width:100%;
	height:100%;
	opacity:.1;
	border-radius: 44% 56% 41% 59% / 45% 34% 66% 55%;
}

.homepage-feature-explanation-container {
	display: grid;
    max-width: 420px;
    height: 100%;
    align-content: center;
}

.homepage-feature-video {
	width: 100%;
    min-width: 250px;
    max-width: 400px;
}

.homepage-feature-title-container {
	display: grid;
	grid-template-columns: auto 1fr;
	column-gap:25px;
	align-items: center;
}

.homepage-feature-title-img {
	height:30px;
}

.homepage-feature-text {
	font-size: 18px;
}

#homepage-sis-container {
	width: fit-content;
	max-width: 700px;
	margin:50px auto 0px auto;
	display: flex;
	flex-wrap: wrap;                  /*  allow items to wrap  */
	justify-content: center; 
	gap:15px;
}

.homepage-sis-logo {
	width: 100%;
	border-radius: 10px;
	max-width: 100px;
}