
#schedule-type-school-name {
	min-width:200px;
}

.schedule-structure-option {
	display: inline-block;
    width: 36%;
    max-width: 400px;
    margin: 0px 2% 0px 0px;
    padding: 20px;
    /* border: 1px solid #a9a9a9; */
    border-radius: 10px;
    background-color: #fff;
    text-align: center;
    box-shadow: 0 0 5px rgba(110, 103, 86, 0.15);
}

.schedule-structure-option-img {
	display:inline-block;
	width:50px;
	margin:0px 30px 0px 0px;
}

.schedule-structure-option-title {
	display:inline-block;
	margin:0px 0px 0px 0px;
	padding:0px;
}

.schedule-structure-option-select-btn {
	width:100%;
	padding:5px;
	text-align:center;
	background-color:#2980b9;
	border-radius:5px;
	color:#fff;
	margin:30px 0px 0px 0px;
	cursor: pointer;
}

.schedule-structure-option-select-btn:hover {
	background-color: #3498db;
}

.schedule-structure-option-select-btn-selected {
	background-color:#2c3e50;
}

.schedule-structure-option-select-btn-selected:hover {
	background-color:#2c3e50;
}