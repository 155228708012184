
@keyframes slide-in{
    0%    {
        right: -380px;
        visibility: hidden;
    }
    100%  {  
        right:0;
        visibility: visible;
    }
}
@keyframes slide-out{
    0%    {
        right:0;  
        visibility: visible;
    }
    100%  {
        right:-380px; 
        visibility: hidden;
    }
}

.elem-modal-screen {
    position: fixed;
    z-index: 9999;
    right: -380px;
    top: 0;
    width: 380px;
    height: 100%;
    background-color: #fff;
    overflow-y: auto;
    z-index: 99999 !important;
    box-shadow: -5px 0px 25px 1px #efefef;
    visibility: hidden;
}

.elem-modal-screen.open{
    -webkit-animation: slide-in 200ms linear forwards;
    -moz-animation: slide-in 200ms linear forwards;
    -o-animation: slide-in 200ms linear forwards;
    -ms-animation: slide-in 200ms linear forwards;
    animation: slide-in 200ms linear forwards;
}
.elem-modal-screen.close{
    -webkit-animation: slide-out 200ms linear forwards;
    -moz-animation: slide-out 200ms linear forwards;
    -o-animation: slide-out 200ms linear forwards;
    -ms-animation: slide-out 200ms linear forwards;
    animation: slide-out 200ms linear forwards;
}

.elem-modal-screen-content {
    position: absolute;
    margin: 0px;
    border: none;
    width: 100%;
    top:58px;
    bottom:90px;
    overflow-y: auto;
    padding: 0px 20px 0px 20px;
    text-align: center;
    z-index: 99999 !important; 
}

.elem-modal-close{
  width: fit-content;
  text-align: center;
  color: #fff;
  font-size: 28px;
  font-weight: bold;
  padding: 0px 10px 0px 10px;
  border-radius: 20px;
  margin: 0px 0px 0px 0px;
}

.elem-modal-close:hover,
.elem-modal-close:focus {
  color: black;
  text-decoration: none;
  cursor: pointer;
  background-color: #efefef;
}

/**********************/
/***** EDIT BLOCK *****/
/**********************/
#elem-top-bar {
    display: grid;
    width:100%;
    grid-template-columns: auto 1fr;
    column-gap: 20px;
    align-items: center;
    padding:10px 20px 10px 10px;
}

#elem-top-bar-btns {
    margin:8px 0px 0px 0px;
    text-align: right;
}

#elem-edit-block-save-row {
    position: absolute;
    left: 0px;
    bottom: 0px;
    right: 0px;
    padding: 20px;
}

#elem-edit-block-main-info {
    display: grid;
    /* margin:30px 0px 0px 0px; */
}

#elem-edit-block-name {
    left: 0px;
    right: 0px;
    border-radius: 5px;
    /* border: none; */
    /* border-bottom: 2px solid #efefef; */
    font-size: 24px;
    padding: 20px;
}

.elem-edit-block-save-btns {
    display: grid;
    grid-template-columns: auto 1fr;
    column-gap: 10px;
    align-items: center;
    min-width: 250px;
}

.elem-edit-block-more-options-btn-container {
    position: relative;
}

#elem-edit-block-time-container {
    display: grid;
    grid-template-columns: 1fr auto 1fr;
    column-gap: 10px;
    align-items: center;
    margin:20px 0px 0px 0px;
}

#elem-edit-block-days-container {
    display: grid;
    grid-template-columns: repeat(auto-fit, 60px);
    column-gap: 8px;
    row-gap: 12px;
    align-items: center;
    justify-items: left;
    margin: 20px 0px;
}

#elem-edit-block-day {
    text-align: left;
    padding: 0px 0px 0px 5px;
}

#elem-edit-block-pull-out-container {
    display: grid;
    grid-template-columns: auto 1fr;
    column-gap: 10px;
    margin:28px 0px 0px 0px;
    align-items: center;
    text-align: left;
}

#elem-edit-block-pull-out-teacher-container {
    margin:-10px 0px 0px 0px;
}

#elem-edit-block-special-teacher-container {
    margin:0px 0px 0px 0px;
}

.elem-edit-block-label {
    font-size: 12px;
    color:#aaa;
    text-align: left;
    margin:18px 0px 5px 0px;
}

.elem-edit-block-student-list {
    text-align: left;
    max-height: 350px;
    overflow-y: auto;
}

.elem-edit-block-student {
    display: grid;
    grid-template-columns: auto 1fr auto;
    align-items: center;
    column-gap: 15px;
    font-size: 16px;
    padding:5px 10px;
}

.elem-edit-block-student:nth-child(odd) {
    background-color: #f5f5f5;
}

.elem-edit-block-student-name {
    display: inline-block;
}

.elem-edit-block-screen-options {
    display: grid;
    grid-template-columns: 1fr 1fr;
    margin: 20px 0px 0px 0px;
    cursor: pointer;
    text-align: left;
}

.elem-edit-block-screen-option {
    padding:5px 0px 5px 5px;
}

.elem-edit-block-screen-option-selected {
    border-bottom: 3px solid #2980b9;
}


/*************************/
/****** EDIT PERIOD ******/
/*************************/
#elem-edit-period-time-container {
    display: grid;
    grid-template-columns: 1fr auto 1fr;
    column-gap: 10px;
    margin:20px 0px 0px 0px;
    align-items: center;
}

#elem-edit-period-days-container {
    display: grid;
    grid-template-columns: repeat(auto-fit, 60px);
    column-gap: 8px;
    row-gap: 12px;
    align-items: center;
    justify-items: left;
    margin: 20px 0px;
}