#management_systems_container {
	display: grid;
    max-width: 600px;
    /* margin: 0px auto 0px auto; */
    grid-template-columns: repeat(3, 1fr);
    justify-items: center;
    align-items: center;
    grid-gap: 40px;
}

.management_system_container {
	position: relative;
	text-align: center;
	background-color: #fff;
	border-radius: 8px;
	box-shadow: 0 0 5px rgba(110, 103, 86, 0.15);
	cursor: pointer;
}

.management_system_logo {
	width:100%;
	border-radius: 8px 8px 0px 0px;
}

.management_system_name {
	color:#2c3e50;
	margin:5px 0px 8px 0px;
}

.management_system_selected {
	border:4px solid #2980b9;
	border-radius:14px;
}

.management_system_container:hover {
	opacity: .9;
}

.choose-sis-check {
	position: absolute;
    top: -15px;
    right: -15px;
    font-size: 30px;
    background-color: #fff;
    border-radius: 20px;
}