#schedule-structure-options-container {
	width:100%;
	margin:0px 0px 20px 0px;
}

.schedule-structure-header {
	margin:0px 0px 15px 0px;
	padding:0px;
}

#days-in-rotation-container {
	margin:0px 0px 30px 0px;
}

#ess-instruction-container {
	position: relative;
	width:100%;
	/*max-width: 600px;*/
	margin:15px auto 30px auto;
	background-color:#fafafa;
	padding:10px 20px 20px 20px;
	border-radius:10px;
	box-shadow: 0 0 5px rgba(110, 103, 86, 0.15);
}

#ess-instruction-header {
	margin:0px;
}

#ess-hide-instructions {
	text-align:center;
    font-size: 16px;
    margin:0px 0px 10px 0px;
    cursor: pointer;
}

#ess-toggle-instructions:hover {
	color:#2c3e50;
}

#ess-instruction-box-container {
	display:grid;
	grid-template-columns: repeat(auto-fit, minmax(100px, 1fr));
	grid-gap: 3rem;
}

#ess-example-box {
	display: inline-block;
	margin:30px auto 0px auto;
}

#ess-instruction-star {
	float:left;
	height:20px;
}

#ess-instruction-text {
	float:left;
	margin:0px 0px 0px 10px;
}

.ess-instruction-box {
	position: relative;
	margin:60px auto 0px auto;
	color:#2c3e50;
	background-color:#fff;
	border:2px solid #efefef;
	border-radius: 8px;
	padding: 0px 20px 30px 20px;
}

.ess-instruction-line-number {
	position: absolute;
	left:50%;
	top:-50px;
	height:100px;
	width:100px;
	margin-left:-50px;
	line-height: 100px;
	font-size: 50px;
	font-weight: 900;
	background-color: #fff;
	color:#2c3e50;
	border:3px solid #efefef;
	border-radius:50px;
}

.ess-instruction-icon {
	height:80px;
	margin:80px 0px 30px 0px;
}

.ess-instruction-line-text {
	margin:2px auto 0px auto;
}

#ess-example-line {
	display: inline-block;
	margin:30px auto 0px auto;
}

#example-schedule-link {
	color:#2980b9;
	cursor: pointer;
}

.ess-drag-dots {
	font-size:20px;
	color:#9b59b6;
}

#ess-main-header-container {
	background-color: #fff;
    padding: 20px 20px 28px 20px;
    border-radius: 70px 70px 10px 10px;
    margin: 70px 0px 0px 0px;
    box-shadow: 0 0 5px rgba(110, 103, 86, 0.15);
	text-align: center;
}

#ess-parent-header {
	margin:0px 0px 0px 0px;
	padding:0px 0px 0px 0px;
	font-size: 30px;
	color:#2c3e50;
}

#show-instructions {
	margin:0px;
	color:#2980b9;
	font-size: 16px;
	cursor: pointer;
}

#show-instructions:hover {
	color:#3498db;
}

#edit-schedule-structure-container {
	position: relative;
    display: grid;
    width: 100%;
    grid-template-columns: min-content 120px;
    grid-column-gap: 2rem;
    column-gap: 2rem;
    margin: 100px 0px 0px 0px;
}

#ess-sortable-schedule-container {
	width: min-content;
}

#ess-header-container {
	width: 100%;
    display: grid;
    max-width: fit-content;
    grid-template-columns: 24px 2fr repeat(5, 1fr);
    grid-template-rows: 30px;
    align-items: center;
}

#ess-header-label {
	display: flex;
	height:100%;
    justify-content: center;
    align-items: center;
    margin: 0px;
}

.ess-header-day {
	display: flex;
	height:100%;
	justify-content: center;
	align-items: center;
	padding:0px 10px 0px 10px;
    color: #2c3e50;
    font-weight: bold;
}

.ess-header-day-inner {
	display: flex;
	width: 100%;
	height:100%;
	justify-content: center;
	align-items: center;
	border-radius: 5px;
	min-width: 80px;
}

.ess-drag-dots-container {
	display: flex;
	height:100%;
	justify-content: center;
	align-items: center;
}

#ess-lunch-block-parent-container {
	display: grid;
}

.ess-period-parent-container {
	display:grid;
	grid-template-columns: 24px 1fr;
	width: 100%;
	max-width: fit-content;
	background-color:#fff;
	margin:10px 0px 0px 0px;
	border-radius: 8px;
	box-shadow: 0 0 5px rgba(110, 103, 86, 0.15);
}

.ess-period-inner-container {
	width:100%;
	display: grid;
	grid-template-columns: 24px 2fr repeat(5, 1fr);
	grid-template-rows: 90px;
	align-items: center;
	padding: 5px 0px 5px 0px;
}

.ess-period-info-outer-container {
	position: relative;
	height:100%;
	padding:10px 30px 10px 30px;
}

.ess-period-info-inner-container {
	height:100%;
}

.ess-period-info-label {
	font-size: 16px;
	color:#8e44ad;
}

.ess-droppable-outer-container {
	height:100%;
	padding:10px;
	min-width:100px;
}

.ess-droppable-inner-container {
	position: relative;
	display: grid;
	height:100%;
	grid-template-columns: 1fr;
	grid-template-rows: 1fr;
	background-color:#fafafa;
	align-items: center;
	border:1px solid #efefef;
	border-radius: 5px;
    padding: 10px;
	color:#a9a9a9;
	font-size: 12px;
	text-align: center;
}

.ess-remove-course-period {
	position: absolute;
    top: 2px;
    left: 4px;
    color: rgba(240,  240,  240, 0.49);
    cursor: pointer;
}

.ess-remove-course-period:hover {
	color: #fff;
}

#ess-lunch-block-header {
	position: relative;
	text-align:left;
	padding:10px 0px 10px 100px;
	margin:0px 0px 0px 0px;
	border-radius: 8px 8px 0px 0px;
}

#ess-lunch-drag-dots {
	position: absolute;
	top:14px;
	left:10px;
	color:#fff;
}

#ess-possible-courses-container {
	display: grid;
	grid-template-columns: 1fr;
	grid-template-rows: 38px;
	min-height: 800px;
	border-left: 1px solid #efefef;
	padding:10px 30px 10px 30px;
	margin:40px 0px 0px 0px;
	background-color: #fff;
	border-radius: 8px;
	box-shadow: 0 0 5px rgba(110, 103, 86, 0.15);
}

#ess-possible-courses-header {
	height: 100%;
	margin:0px 0px 10px 0px;
}

.course-period-draggable {
	position: relative;
    width: 100%;
    max-width: 100px;
    height: 60px;
    margin:0px 0px 10px 0px;
    text-align: center;
    padding: 10px;
    border-radius: 5px;
    box-shadow: 10px 10px 16px -4px rgba(208,208,208,.5);
}

.schedule-setup-course-period-dots {
	position: absolute;
    height: 100%;
    width: 20px;
    display: flex;
    top: 0px;
    left: 0px;
    justify-content: center;
    align-items: center;
}

.schedule-setup-course-period-name {
	display: flex;
    height: 100%;
    width: 100%;
    justify-content: center;
    align-items: center;
}

.schedule-setup-edit-course-period-name {
	position: absolute;
	top:5px;
	right:3px;
	font-size:12px;
	color:rgba(239, 239, 239, 0.5);
	cursor: pointer;
}

.schedule-setup-edit-course-period-name:hover {
	color:#fff;
}

.ess-period-name-container {
	position: relative;
	display: inline-block;
}

.schedule-setup-edit-schedule-period-name {
	position: absolute;
    top: 0px;
    right: -25px;
    font-size: 12px;
    color: #dddddd;
    cursor: pointer;
}

.schedule-setup-edit-schedule-period-name:hover {
	color:#34495e;
}

.schedule-setup-course-period-name-input-container {
	width: 100%;
    height: 100%;
    justify-content: center;
    align-items: center;
}

.schedule-setup-course-period-name-input {
	display: block;
    height: 22px;
    line-height: 22px;
    width: 100%;
    border: 2px solid #efefef;
    border-radius: 3px;
}

.ess-edit-icon-container {
	display: grid;
    width: 100%;
    grid-template-columns: 1fr 1fr;
    justify-items: center;
    margin: 2px 0px 0px 0px;
}

.ess-edit-icon {
	display: inline-block;
	margin:3px;
	color:#efefef;
	cursor: pointer;
}

.ess-edit-icon:hover {
	color:#fff;
}

.schedule-setup-period-name-input {
	width: 200px;
    height: 30px;
    border: 2px solid #efefef;
    border-radius: 4px;
    margin: -5px 0px 0px 0px;
    padding: 2px 0px 2px 8px;
}

.ess-cancel-edit-schedule-period {
	position: absolute;
    top: 5px;
    right: 34px;
    color: #dfdfdf;
    cursor: pointer;
}

.ess-cancel-edit-schedule-period:hover {
	color:#c0392b;
}

.ess-confirm-edit-schedule-period {
	position: absolute;
    top: 5px;
    right: 6px;
    color: #dfdfdf;
    cursor: pointer;
}

.ess-confirm-edit-schedule-period:hover {
	color:#27ae60;
}

#schedule-structure-edit-error {
	width:600px;
	margin:40px auto -25px auto;
}

.toggle-lower-options {
	margin:0px 20px 0px 0px;
	color:#bfbfbf;
	cursor: pointer;
}

.toggle-higher-options {
	margin:0px 0px 0px 5px;
	color:#bfbfbf;
	cursor: pointer;
}

.toggle-lower-options:hover, .toggle-higher-options:hover {
	color:inherit;
}

.sortable-placeholder {
	background-color: #ffeaa7;
	height:100px;
	margin:10px 0px 10px 0px;
	border-radius: 8px;
}













