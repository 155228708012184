#settings-main-container {
	display: grid;
}

#settings-sidebar {
    position: fixed;
    height: 100%;
	width: 220px;
	background-color: #fff;
	padding:0px 0px 200px 0px;
	overflow-y: auto;
}

.settings-sidebar-btn {
	position: relative;
    display: grid;
    grid-template-columns: auto 1fr auto;
    grid-column-gap: 15px;
    column-gap: 15px;
    align-items: center;
    padding: 15px 15px 15px 18px;
    font-size: 12px;
	color:#2c3e50;
    cursor: pointer;
}

.settings-sidebar-btn:not(.settings-sidebar-btn-selected):hover {
	background-color: #f8f8f8;
}

.settings-sidebar-btn-selected {
	background-color: #34495e;
	color:#fff;
}

#settings-main-content {
	padding:0px 0px 0px 220px;
}

#settings-main-content-inner {
	position: relative;
	padding:30px 60px;
}

.page-main-heading {
	font-size:40px;
}

/****************************/
/***** ACCOUNT SETTINGS *****/
/****************************/
#account-settings-container {
	width: 100%;
    max-width: 800px;
	min-width: 750px;
    text-align: left;
    padding: 0px 40px 40px 40px;
    background-color: #fff;
    border-radius: 10px;
    box-shadow: 0 0 5px rgba(110, 103, 86, 0.15);
}

.account-settings-input {
	height: 40px;
	line-height: 40px;
	font-size: 16px;
	background-color: #f0f5fb;
}

#account-settings-top-bar {
	display: grid;
	grid-template-columns: 1fr;
	grid-column-gap: 50px;
	align-items: center;
	margin:0px 0px -10px 0px;
}

#account-settings-user-img-container {
	position: relative;
	text-align: center;
	margin: 15px 0px 15px 0px;
}

.account-settings-user-img {
	font-size: 150px;
    height: 150px;
    width: 150px;
    border-radius: 150px;
    color: #eaeff6;
}

#account-settings-top-bar-summary {
	text-align: left;
}

#account-settings-info-container {
	display: grid;
	grid-template-columns: 1fr 1fr;
	grid-column-gap: 30px;
}

.account-settings-info-group {
	height:min-content;
	padding:15px 20px 20px 20px;
	margin:40px 0px 10px 0px;
	border:1px solid #efefef;
	border-radius: 10px;
}

.account-settings-info-group h3 {
	margin:0px 0px 0px 0px;
}

#account-settings-name-container {
	display: grid;
	grid-template-columns: 1fr 1fr;
	grid-column-gap: 30px;
	align-items: center;
	margin:0px 0px 0px 0px;
}

.account-settings-label {
	font-size: 13px;
	color: #34495e;
	margin:10px 0px 5px 0px;
}

#account-settings-password-container {
	display: grid;
}

.account-settings-input-container {
	position: relative;
}

.account-settings-toggle-hidden-icon {
	position: absolute;
    top: 13px;
    right: 13px;
}

.account-settings-btn {
	margin:30px 0px 0px 0px;
}

#account-settings-notifications-container {
	display: grid;
	grid-template-columns: 1fr auto;
	grid-column-gap: 30px;
	align-items: center;
	margin:20px 0px 0px 0px;
}

.account-settings-message {
	margin:20px 0px -10px 0px;
}

/*************************/
/***** USER SETTINGS *****/
/*************************/
.user-accounts-container {
	width:100%;
	max-width: 860px;
	min-width: 700px;
	margin:30px 0px 0px 0px;
	padding:0px;
	background-color:#fff;
	border-radius: 8px;
	box-shadow: 0 0 5px rgba(110, 103, 86, 0.15);
}

.user-account-header {
	display: grid;
	grid-template-columns: 20px 1fr;
	grid-column-gap: 20px;
	align-items: center;
	padding:10px 20px;
	border-radius: 8px 8px 0px 0px;
}

.user-account-header-icon {
	height:20px;
	width:20px;
}

.user-account-header-label {
	font-size: 20px;
}

.user-accounts-inner-container {
	padding: 20px 30px 15px 30px;
}

.user-accounts-left {
	margin:0px 0px 0px 20px;
	font-size: 14px;
	color:#f1af2a;
}

.user-row {
	width:100%;
	display: grid;
	grid-template-columns: 2fr 3fr 2fr 50px;
	column-gap: 30px;
	align-items: center;
	margin:0px 0px 20px 0px;
	padding:15px 20px 15px 20px;
	border:1px solid #efefef;
	border-radius: 8px;
	font-size: 14px;
	/*background-color: #fbfbfb;*/
}

.user-verified {
	margin:0px 0px 0px 10px;
}

.user-delete {
	color:#ddd;
	cursor: pointer;
}

.user-delete:hover {
	color:#c0392b;
}

.user-edit {
	margin:0px 20px 0px 0px;
	color:#ddd;
	cursor: pointer;
}

.user-edit:hover {
	color:#34495e;
}

.users-add-new-user-row {
	width:100%;
	margin:0px 0px 20px 0px;
	padding:15px 20px 20px 20px;
	border:2px solid #efefef;
	border-radius:8px;
}

.users-add-new-user-heading {
	font-size: 18px;
	margin:0px 0px 13px 0px;
}

.users-add-new-user-inner-row {
	width:100%;
	display: grid;
	grid-template-columns: 2fr 2fr 2fr 50px;
	grid-column-gap: 20px;
	align-items: center;
} 

.users-add-new-user-label {
	font-size: 12px;
}

.users-add-new-user-input {
	width:100%;
	height:35px;
	line-height: 35px;
	padding:0px 0px 0px 8px;
	border:2px solid #efefef;
	border-radius: 5px;
}

.users-add-new-btns {
	text-align: right;
}

.users-add-new-icons {
	color:#ddd;
	font-size: 20px;
	cursor: pointer;
}

.users-add-new-exit {
	margin:0px 0px 0px 15px;
}

.users-add-new-confirm:hover {
	color:#27ae60;
}

.users-add-new-exit:hover {
	color:#c0392b;
}

.users-add-new-user-btn {
	width:100%;
	margin:30px 0px 0px 0px;
	text-align: right;
	color:#2980b9;
	cursor: pointer;
}

.users-add-new-user-btn:hover {
	color:#3498db;
}

.add-new-user-error-message {
	text-align: center;
	font-size: 13px;
	margin:15px 0px 0px 0px;
	color:#c0392b;
}

.users-status-message {
	text-align: center;
	font-size: 13px;
	margin:15px 0px 0px 0px;
}